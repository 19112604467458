<template>

    <div>


        <!-- El DIALOG para agregar nuevo padecimiento -->
            <v-dialog v-model="dialogAgrega" width="800" persistent>
                <v-card>
                    <v-form
                        v-model="validaDiagForm"
                        ref="validaDiagForm"

                        @submit="agregaDiag"
                        onSubmit="return false;"
                    >
                        <v-card-title
                            class="headline blue lighten-2 white--text"
                            primary-title
                            
                        >
                            Agrega DIAGNÓSTICO
                        </v-card-title>

                        <v-card-text class="pt-5">


                            <!-- El autocomplete de los diagnosticos -->
                            <v-autocomplete
                                append-outer-icon="mdi-magnify"
                                @click:append-outer='abreBuscaDiag'
                                
                                v-model="nuevoDiag.padPadecimiento"
                                
                                :items="listaPadecimienos"
                                :loading="loadingBuscaPad"
                                
                                label="Padecimiento (CIE-10 opcional)"
                                :search-input.sync="queryPad"
                                item-text="codigo"
                                item-value="id"

                                no-filter

                                filled
                                rounded
                                outlined
                                dense
                                no-data-text='No ha datos para mostrar'
                                
                            >
                                <!-- El template para la lista de los datos -->
                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title>{{data.item.codigo}} - {{data.item.padecimiento}}</v-list-item-title>
                                        <v-list-item-subtitle>{{data.item.capitulo}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>{{data.item.seccion}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                                
                                <!-- El template para el control de paginate -->
                                <template v-slot:append-item>
                                    <v-pagination
                                        v-model="pageAc"
                                        circle
                                        :length="pageLengthAc"
                                        total-visible="7"
                                        :disabled="loadingBuscaPad"
                                    ></v-pagination>
                                </template>

                                <template v-slot:selection="data">
                                    ({{data.item.codigo}}) {{data.item.padecimiento}}
                                </template>



                            </v-autocomplete>


                            <v-text-field
                                v-model="nuevoDiag.padPadecimientoNombreComun"

                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"

                                filled
                                rounded
                                outlined
                                dense

                                label="Padecimiento (nombre común)"
                            />


                            <v-row no-gutters class="pb-5"> 
                                <v-col xs="12" sm="6" md="6" lg="3" xl="3" cols="12">
                                    <v-checkbox :true-value='1' :false-value='0' hide-details v-model="nuevoDiag.padProbable" label="Probable" dense></v-checkbox>
                                </v-col>
                                <v-col xs="12" sm="6" md="6" lg="3" xl="3" cols="12">
                                    <v-checkbox :true-value='1' :false-value='0' hide-details v-model="nuevoDiag.padIntrahospitalaria" label="I. A. A. S." dense></v-checkbox>
                                </v-col>

                                <v-col xs="12" sm="6" md="6" lg="3" xl="3" cols="12">
                                    <v-checkbox :true-value='1' :false-value='0' hide-details v-model="nuevoDiag.padAccidental" label="Accidental" dense></v-checkbox>
                                </v-col>

                                <v-col xs="12" sm="6" md="6" lg="3" xl="3" cols="12">
                                    <v-checkbox :true-value='1' :false-value='0' hide-details v-model="nuevoDiag.padDefuncion" label="Defunción" dense></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-textarea
                                v-model="nuevoDiag.observaciones"
                                filled
                                rounded
                                outlined
                                dense

                                label="Observaciones"
                            />

                            
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="error" @click="cancela">
                                Cerrar
                            </v-btn>

                            <v-btn
                                color="success"
                                type="submit"
                                :disabled="!validaDiagForm"
                                :loading="loadingDiagForm"
                            >
                                Agrega
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>

            <!-- Dialog para catálogo de diagnósticos -->
            <catalogo-diagnostico
                :dialogCatCie.sync='dialogCatCie'
                :listaPadecimienos.sync='listaPadecimienos'
                :esteDiagId.sync='nuevoDiag.padPadecimiento'
            />

        
    </div>
</template>

<script>
import CatalogoDiagnostico from '@/components/diagnosticos/catalogoDiagnostico'
export default {
    components:{
        'catalogo-diagnostico':CatalogoDiagnostico
    },

    props:{

        dialogAgrega:{
            type:Boolean,
        },
        paciente:{
            type:Number,
        },
        //listaPadecimienos:{
        //    type: Array,
        //},
        padPadecimiento:{
            type:Number,
        }


    },

    data:()=>({

        loadingDiagForm:false,
        validaDiagForm:false,

        nuevoDiag:{
            padPadecimiento: null,
            padPadecimientoNombreComun: '',
            padProbable: null,
            padIntrahospitalaria: null,
            padAccidental: null,
            padDefuncion: null,
            observaciones: '',
        },

        

        // Para el autocomplete
        listaPadecimienos:[],
        loadingBuscaPad:false,
        queryPad:'',

        // Pagging del autocomplete
        paramsAc:{},

        // Para la paginación
        limitAc:10,
        pageAc:1,
        totalAc:0,

        // Para catálogo de diagnósticos:
        dialogCatCie:false,
        
    }),

    methods:{
        
        async cargaPadecimientos(/*query*/){
            
            if (!this.loadingMeds){
                if (this.queryPad && this.queryPad.length >= 3){

                    this.loadingBuscaPad = true

                    this.paramsAc.diagnostico = this.queryPad
                    this.paramsAc.page = this.pageAc
                    this.paramsAc.start = (this.pageAc -1) * this.limitAc
                    this.paramsAc.limit = this.limitAc
        
                    try {
                        var resp = await this.$http
                        .get('/cie10Dgis/cie10/',{
                            params:this.paramsAc,
                        })
                        this.listaPadecimienos = resp.data.root
                        this.totalAc = resp.data.total
                        this.loadingBuscaPad = false
                        
                    } catch(err) {
                        console.log(err)
                        this.loadingBuscaPad = false
                        this.$store.dispatch('security/revisaError',err)
                    }
                }
            }
        },
        cancela(){
            this.$refs.validaDiagForm.reset()
            this.dialog = false
        },

        async agregaDiag(){

            this.loadingDiagForm=true

            let params = {...this.nuevoDiag}
            params.paciente = this.paciente

            try{
                let nd = await this.$http({
                    method:'POST',
                    url:'/hospital/guardaPadecimiento',
                    params:params
                })
                
                this.loadingDiagForm=false

                if (nd.data.estado == 'true'){
                    this.$refs.validaDiagForm.reset()
                    this.dialog=false
                    this.$emit('agregado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }




            } catch(err){
                this.loadingDiagForm=false
                this.$store.dispatch('security/revisaError',err)
            }

            //console.log(this.nuevoDiag)
        },

        abreBuscaDiag(){
            this.dialogCatCie=true
            //console.log('Buscar un nuevo padecimiento CIE 10')
        },
    },

    computed:{
        // dialog
        dialog:{
            get(){
                return this.dialogAgrega
            },
            set(val){
                this.$emit('update:dialogAgrega',val)
            },
        },
        // list
        // padecimientoSelected

        // pageLengthAc
        pageLengthAc(){
            return (Math.ceil(this.totalAc / this.limitAc));
        },

    },

    watch:{
        queryPad(){
            this.pageAc = 1
            this.cargaPadecimientos()
            
            
        },
        pageAc(){
            
            this.cargaPadecimientos()
        }

    },
}
</script>

<style>

</style>