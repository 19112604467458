<template>
    <div>
        <div v-if="loadingPad">
            <v-skeleton-loader
                class="mx-auto"
                type="article, article"
            ></v-skeleton-loader>

        </div>


        <div v-if="!loadingPad">
            
            <v-toolbar dense flat>
                <v-btn outlined color="success" small text @click="dialogAgrega=true">
                    <v-icon>mdi-plus</v-icon> Agregar
                </v-btn>
                <v-spacer/>
                <v-switch
                    class="mt-3"
                    v-model="todosPad"
                    label="Todos"
                    @change="getPade"
                ></v-switch>
            </v-toolbar>

            <v-alert class="mx-2" type="info" text border="left" v-if="padecimientos.length == 0" dense>
                No hay padecimientos. Por favor 
                <v-btn outlined color="primary" text @click="dialogAgrega=true">
                    AGREGAR
                </v-btn>
            </v-alert>

            <v-list>
                <v-list-item-group v-model="selection">
                    <v-list-item three-line v-for="itm in padecimientos" :key="itm.id">

                        <v-list-item-avatar v-if="!itm.finalizado">
                            <v-btn small icon @click="abreTermina(itm)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <v-list-item-title style="white-space: pre-line;">
                                {{itm.padecimientoNombreComun}}
                            </v-list-item-title>

                            <v-list-item-subtitle v-if="itm.padecimientoId" style="white-space: pre-line;">
                                ({{itm.padecimientoCode}}) {{itm.padecimiento}}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle style="white-space: pre-line;">
                                <span >Registrado {{itm.dateCreated}} por {{itm.creado}}</span>
                                <span v-if="itm.fechaFinalizacion" class="red--text"><br/>Finalizado {{itm.fechaFinalizacion}} por {{itm.finalizado}}</span>
                            </v-list-item-subtitle>
                        <hr>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn small icon @click="padDetalle(itm)">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <!-- El DIALOG para mostrar las observaciones -->
            <v-dialog v-model="dialogDetalles" width="450">
                <v-card>
                    <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                    >
                        Detalles de la entrada
                    </v-card-title>
                    <v-card-text class="pt-5">

                        <v-alert type="error" text border="left" v-if="padecimientoDetalles.fechaFinalizacion" dense>
                            Padecimiento finalizado el {{padecimientoDetalles.fechaFinalizacion}} por {{padecimientoDetalles.finalizado}}
                        </v-alert>

                        <span class="font-weight-black">Observaciones:</span>
                            {{padecimientoDetalles.observaciones}}
                            <span class="orange--text" v-if="!padecimientoDetalles.observaciones">No hay observaciones</span><br/>


                        <div v-if="padecimientoDetalles.fechaFinalizacion">
                            <span class="font-weight-black">Observaciones de cancelación:</span>
                                {{padecimientoDetalles.observacionesFinalizado}}
                                <span class="orange--text" v-if="!padecimientoDetalles.observacionesFinalizado">No hay observaciones</span><br/>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" @click="dialogDetalles=false">
                            Cerrar
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>

            


            

        </div>
        
        <agrega-padecimiento
            :dialogAgrega.sync='dialogAgrega'
            :paciente='paciente'
            @agregado="getPade"
        />
        
    </div>
</template>

<script>
//import CatalogoDiagnostico from '@/components/diagnosticos/catalogoDiagnostico'
import AgregaPadecimiento from '@/components/diagnosticos/agregaPadecimiento'
export default {

    mounted(){
        this.getPade()
    },

    components:{
        //'catalogo-diagnostico':CatalogoDiagnostico,
        'agrega-padecimiento':AgregaPadecimiento,
    },

    props:{
        paciente:{
            type:Number
        },
        estePade:{
            type:Object
        },
        
    },

    data:()=>({

        validaDiagForm:false,
        //loadingDiagForm:false,

        /* Se mueve a parametro de guardar
        nuevoDiag:{
            padPadecimiento: null,
            padPadecimientoNombreComun: '',
            padProbable: null,
            padIntrahospitalaria: null,
            padAccidental: null,
            padDefuncion: null,
            observaciones: '',
        },
        */

        selection:[],
        loadingPad: false,
        padecimientos:[],
        total:0,
        
        padecimientoDetalles:{},

        todosPad:false,

        dialogDetalles:false,

        //dialogAgrega:false,
        dialogAgrega:false, //Para lo que agrega inline

        // Form para agregar padecimientos
        datosPad:{
            padecimiento:null,
        },
        
        /*
        // Pasado a componente
        // Para el autocomplete
        listaPadecimienos:[],
        loadingBuscaPad:false,
        queryPad:'',

        // Pagging del autocomplete
        paramsAc:{},

        // Para la paginación
        limitAc:10,
        pageAc:1,
        totalAc:0,

        // Para catálogo de diagnósticos:
        dialogCatCie:false,
        */

    

    }),


    methods:{

        padDetalle(data){
            //console.log('Listo para entregar')
            this.padecimientoDetalles=(data)
            this.dialogDetalles = true
            //console.log(this.esteValor)
            
        },

        async getPade(){

            this.loadingPad = true
            let params = {paciente:this.paciente}

            if (this.todosPad){
                params.todos = true
            }
            
            try{
                let pd = await this.$http({
                    url:'/hospital/listaPadecimientos',
                    params:params
                })

                this.loadingPad = false

                this.padecimientos = pd.data.root
                this.total = pd.data.total
                
            } catch(err){
                this.loadingPad = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        //abreBuscaDiag(){
        //    this.dialogCatCie=true
            //console.log('Buscar un nuevo padecimiento CIE 10')
        //},

        abreTermina(pade){
            this.$swal.fire({
                title: 'Terminar padecimiento',
                text: 'Observaciones para terminar este padecimiento',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Terminar',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {
                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.termina(pade.id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },
        /*
        // Se fué a componente
        async agregaDiag(nuevoDiag){

            this.loadingDiagForm=true

            let params = {...nuevoDiag}
            params.paciente = this.paciente

            try{
                let nd = await this.$http({
                    method:'POST',
                    url:'/hospital/guardaPadecimiento',
                    params:params
                })
                
                this.loadingDiagForm=false

                if (nd.data.estado == 'true'){
                    //this.$refs.validaDiagForm.reset()
                    this.dialogAgrega=false
                    this.getPade()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }




            } catch(err){
                this.loadingDiagForm=false
                this.$store.dispatch('security/revisaError',err)
            }

            //console.log(this.nuevoDiag)
        },
        */

        //cancela(){
        //    this.$refs.validaDiagForm.reset()
        //    this.dialogAgrega=false
        //},
         // Carga la lista de padecimientos para el autocompletye

        /*
        // Se fué a componente
        async cargaPadecimientos(){
            
            if (!this.loadingMeds){
                if (this.queryPad && this.queryPad.length >= 3){

                    this.loadingBuscaPad = true

                    this.paramsAc.diagnostico = this.queryPad
                    this.paramsAc.page = this.pageAc
                    this.paramsAc.start = (this.pageAc -1) * this.limitAc
                    this.paramsAc.limit = this.limitAc
        
                    try {
                        var resp = await this.$http
                        .get('/cie10Dgis/cie10/',{
                            params:this.paramsAc,
                        })
                        this.listaPadecimienos = resp.data.root
                        this.totalAc = resp.data.total
                        this.loadingBuscaPad = false
                        
                    } catch(err) {
                        console.log(err)
                        this.loadingBuscaPad = false
                        this.$store.dispatch('security/revisaError',err)
                    }
                }
            }
        },
        */

        async termina(id, text){
            this.loadingPad = true
            let params = {
                terminado:1,
                id:id,
                observacionesFinalizado:text,
            }

            try{
                let bp = await this.$http({
                    url:'/hospital/guardaPadecimiento',
                    method:'POST',
                    params:params
                })

                if (bp.data.estado == 'true'){
                    this.loadingPad = false
                    this.getPade()
                } else {
                    console.log(bp.data)
                    this.loadingPad = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede actualizar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingPad = false
                this.$store.dispatch('security/revisaError',err)
            }
            //console.log(id)
            //console.log(text)


        }
    },
    computed:{
        /*
        // Probando
        diagn:{
            get(){
                return this.estePade
            },
            set(val){
                this.$emit('update:estePade',val)
            },
        },
        */
        // Para el autocomplete de padecimientos
        // Pasado a componente
        //pageLengthAc(){
        //    return (Math.ceil(this.totalAc / this.limitAc));
        //},

        
    },

    watch:{
        /*
        // Al seleccionar un padecimiento, envia el objeto al padre
        selection(){
            //console.log(this.selection >= 0)
            if (this.selection >= 0){
                //this.padecimientos[2]
                //console.log(this.padecimientos[this.selection].id)
                this.$emit('update:estePade',this.padecimientos[this.selection])
            } else {
                this.$emit('update:estePade',{})
            }
        },
        */
        // Para el autocomplete de paecimientos
        // Cuando se actualiza el queru
        /*
        // Se va a componente
        queryPad(){
            this.pageAc = 1
            this.cargaPadecimientos()
            
            
        },
        */
        // Cuando se cambia de página
        /*
        // Se va a componente
        pageAc(){
            this.cargaPadecimientos()
        }
        */

    },
    
}
</script>

<style>

</style>