<template>
    <v-dialog v-model="dialog" width="800">
        <v-card>
            <v-card-title
                class="headline grey lighten-2"
                primary-title
            >
                Buscar procedimiento
            </v-card-title>
            <v-card-text class="pt-5">
                <v-select
                    class="py-2"
                    :items="capituloList"
                    v-model="filtroPad.capitulo"
                    :loading='loadingCap'
                    item-text="capitulo"
                    item-value="id"
                    filled
                    outlined
                    rounded
                    clearable
                    dense
                    hide-details
                    label="Capítulo"

                    @click="cargaCapitulos"
                ></v-select>

                <v-select
                    class="py-2"
                    :items="seccionList"
                    v-model="filtroPad.seccion"
                    :loading='loadingSec'
                    item-text="seccion"
                    item-value="id"
                    :disabled="!filtroPad.capitulo"
                    filled
                    outlined
                    rounded
                    clearable
                    dense
                    hide-details
                    label="Sección"

                    @click="cargaSecciones"
                ></v-select>

                <v-select
                    class="py-2"
                    :items="categoriaList"
                    v-model="filtroPad.categoria"
                    :loading='loadingCat'
                    item-text="categoria"
                    item-value="id"
                    :disabled="!filtroPad.seccion"
                    filled
                    outlined
                    rounded
                    clearable
                    dense
                    hide-details
                    label="Categoría"
                    @click="cargaCategorias"
                ></v-select>

                <v-text-field
                    class="py-2"
                    v-model="filtroPad.diagnostico"
                    filled
                    outlined
                    rounded
                    clearable
                    dense
                    hide-details
                    label="Procedimiento"
                ></v-text-field>

                <div class='d-flex justify-end'>
                    <v-btn
                        color="primary"
                        :loading="loadingPad"
                        @click="buscaDiagnostico"
                    >
                        Buscar
                    </v-btn>
                </div>


                <v-list three-line>
                    <v-list-item
                        v-for="item in listaPadecimienos"
                        :key="item.id"
                    >
                        <v-list-item-content>
                            <v-list-item-title>({{item.codigo}}) {{item.padecimiento}}</v-list-item-title>
                            <v-list-item-subtitle>{{item.capitulo}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{item.seccion}}</v-list-item-subtitle>
                            <v-divider/>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                @click="seleccionaEste(item)"
                                icon
                            >
                                <v-icon color="success">mdi-check-bold</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-pagination
                    v-model="pageAc"
                    circle
                    :length="pageLengthAc"
                    total-visible="7"
                    :disabled="loadingPad"
                ></v-pagination>
            </v-card-text>
                            
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogCatCie:{
            type:Boolean
        },
        listaPadecimienos:{
            type:Array
        },
    },

    data:()=>({

        //Para los combos del catalogo
        capituloList:[],
        loadingCap:false,

        seccionList:[],
        loadingSec:false,

        categoriaList:[],
        loadingCat:false,

        loadingPad:false,

        filtroPad:{
            capitulo:null,
            seccion:null,
            categoria:null,

            diagnostico:'',
        },

        // Para la paginación
        limitAc:10,
        pageAc:1,
        totalAc:0,
        // Para la paginación
    }),

    methods:{
        buscaDiagnostico(){
            this.pageAc=1
            this.cargaDiagnosticos()
        },
        async cargaCapitulos(){
            this.loadingCap=true
            try{
                let cap = await this.$http({
                    method:'GET',
                    url:'/cie9mc/cie9Cap',
                })
                this.capituloList = cap.data
                this.loadingCap=false

            } catch(err){
                this.loadingCap=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaSecciones(){
            this.loadingSec=true
            try{
                let cap = await this.$http({
                    method:'GET',
                    url:'/cie9mc/cie9sec',
                    params:{id:this.filtroPad.capitulo}
                })
                this.seccionList = cap.data
                this.loadingSec=false

            } catch(err){
                this.loadingSec=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaCategorias(){
            this.loadingCat=true
            try{
                let cap = await this.$http({
                    method:'GET',
                    url:'/cie9mc/cie9cat',
                    params:{id:this.filtroPad.seccion}
                })
                this.categoriaList = cap.data
                this.loadingCat=false

            } catch(err){
                this.loadingCat=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaDiagnosticos(){
            //console.log(this.filtroPad)

            this.loadingPad = true

            //this.paramsAc.diagnostico = this.queryPad
            this.filtroPad.page = this.pageAc
            this.filtroPad.start = (this.pageAc -1) * this.limitAc
            this.filtroPad.limit = this.limitAc

            try {
                var resp = await this.$http
                .get('/cie9mc/cie9mc/',{
                    params:this.filtroPad,
                })
                this.list = resp.data.root
                this.totalAc = resp.data.total
                this.loadingPad = false
                
            } catch(err) {
                this.loadingPad = false
                this.$store.dispatch('security/revisaError',err)
            }


        },

        seleccionaEste(itm){
            //console.log(itm.id)
            this.$emit('update:esteDiagId',itm.id)
            this.dialog=false
        }
    },


    computed:{
        dialog:{
            get(){
                return this.dialogCatCie
            },
            set(val){
                if (val == false){
                    this.filtroPad={
                        capitulo:null,
                        seccion:null,
                        categoria:null,
                        diagnostico:'',
                    }
                    this.capituloList=[]
                    this.seccionList=[]
                    this.categoriaList=[]


                }
                this.$emit('update:dialogCatCie',val)

            },


        },
        list:{
            get(){
                return this.listaPadecimienos
            },
            set(val){
                this.$emit('update:listaPadecimienos',val)
            },
        },

        pageLengthAc(){
            return (Math.ceil(this.totalAc / this.limitAc));
        },
    },
    watch:{
        "filtroPad.capitulo"(){
            //if (!this.filtroPad.capitulo){

                this.filtroPad.seccion = null
                this.seccionList=[]


                this.filtroPad.categoria = null
                this.categoriaList=[]
            //}
            
        },
        "filtroPad.seccion"(){
            //if (!this.filtroPad.seccion){
                this.filtroPad.categoria = null
                this.categoriaList=[]
            //}
            //
        },

        "filtroPad.categoria"(){
        },

        pageAc(){
            //this.pageAc=1
            this.cargaDiagnosticos()
        }
    },

}
</script>

<style>

</style>