<template>

    <v-dialog
        v-model="dialog"
        width="700"
        persistent
    >
        <v-card>
            <v-form v-model="formValid" ref="formulario">
                <v-toolbar color="green" dark>
                    <v-toolbar-title>
                        <span v-if="!datos.id">
                            Nuevo reporte
                        </span>

                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon @click="dialog=false">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <span class="text-h6">Reporte de eventos adversos</span>
                    <v-text-field
                        label="Descripción breve"
                        v-model="nd.descripcionBreve"
                        dense
                        rounded
                        filled
                        :rules='[
                            value => !!value || "Requerido",
                        ]'
                    />
                            <v-row no-gutters>

                                <v-col md=5 cols=12>
                                    <div class="d-flex">
                                        <v-text-field
                                            class="flex-grow-1 flex-shrink-0"
                                            label="Fecha en que ocurrió"
                                            v-model="nd.fecha"
                                            dense
                                            rounded
                                            filled
                                            :rules='[
                                                value => !!value || "Requerido",
                                                value => /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo 31-01-2021",
                                            ]'
                                        />
                                            <v-menu
                                                v-model="fechaModal"
                                                bottom
                                                left
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon @click="fechaModal=true" v-on="on"><v-icon>mdi-calendar</v-icon></v-btn>
                                                </template>

                                                <v-date-picker
                                                    v-model="fechaPicker"
                                                    no-title
                                                    @input="cambiaFecha"
                                                />
                                            </v-menu>

                                    </div>

                                </v-col>

                                <v-col md=4 cols=12>
                                    <div class="d-flex">
                                        <v-text-field
                                            label="Hora"
                                            v-model="nd.hora"
                                            dense
                                            rounded
                                            filled
                                            :rules="[
                                                value => !!value || 'Requerido.',
                                                value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                            ]"
                                        />
                                        <v-menu
                                            v-model="horaModal"
                                            bottom
                                            left
                                            :close-on-content-click="false"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon @click="horaModal=true" v-on="on"><v-icon>mdi-clock</v-icon></v-btn>
                                            </template>

                                            <v-time-picker
                                                v-model="nd.hora"
                                                format="24hr"
                                                @change="horaModal=false"
                                            />
                                        </v-menu>
                                    </div>
                                </v-col>

                                <v-col md=3 cols=12>
                                    <v-select
                                        label="Turno"
                                        v-model="nd.turno"
                                        :items="turnos"
                                        dense
                                        rounded
                                        filled
                                        :rules='[
                                            value => !!value || "Requerido",
                                        ]'
                                    />
                                </v-col>

                                <v-col cols=12>
                                    <v-text-field
                                        label="Lugar en que ocurrió el incidente"
                                        v-model="nd.lugar"
                                        dense
                                        rounded
                                        filled
                                        :rules='[
                                            value => !!value || "Requerido",
                                        ]'
                                    />
                                </v-col>


                                <v-col md=6 cols=12>
                                    <v-select
                                        :items="datosSelect"
                                        label="Personal implicado"
                                        v-model="nd.implicado"
                                        chips
                                        deletable-chips
                                        dense
                                        rounded
                                        filled
                                        multiple
                                        :rules='[
                                            value => !!value || "Requerido.",
                                            value => value?value.length > 0:null || "Requerido."
                                        ]'
                                    />
                                    <v-expand-transition>

                                        <v-text-field
                                            v-if="nd.implicado?nd.implicado.includes(99):false"
                                            label="Otro implicado"
                                            v-model="nd.implicadoOtro"
                                            dense
                                            rounded
                                            filled
                                            :rules='[
                                                value => !!value || "Requerido.",
                                            ]'
                                        />
                                    </v-expand-transition>
                                </v-col>

                                <v-col md=6 cols=12>
                                    <v-select
                                        :items="datosSelect"
                                        label="Personal que presencia"
                                        v-model="nd.presencia"
                                        chips
                                        deletable-chips
                                        dense
                                        rounded
                                        filled
                                        multiple
                                        :rules='[
                                            value => !!value || "Requerido.",
                                            value => value?value.length > 0:null || "Requerido."
                                        ]'
                                    />
                                    <v-expand-transition>
                                        <v-text-field
                                            v-if="nd.presencia?nd.presencia.includes(99):false"
                                            label="Otro que presencia"
                                            v-model="nd.precenciaOtro"
                                            dense
                                            rounded
                                            filled
                                            :rules='[
                                                value => !!value || "Requerido.",
                                            ]'
                                        />
                                    </v-expand-transition>
                                </v-col>

                                <v-col cols=12>
                                    <v-textarea
                                        label="Descripción detallada del evento"
                                        v-model="nd.descripcionDetallada"
                                        dense
                                        rounded
                                        filled
                                        :rules='[
                                            value => !!value || "Requerido.",
                                        ]'
                                    />
                                </v-col>

                                <v-col md=6 cols=12>
                                    <v-checkbox
                                        label="Se informa a familiar o paciente"
                                        v-model="nd.informaFamiliar"
                                    />
                                </v-col>
                                <v-col md=6 cols=12>
                                    <v-text-field
                                        label="¿Quien proporciona la información?"
                                        v-model="nd.personaProporcionaInfo"
                                        dense
                                        rounded
                                        filled
                                    />
                                </v-col>
                                <v-col cols=12>
                                    Gravedad
                                    <v-radio-group v-model="nd.gravedad">
                                        <v-radio value="sin" label="SIN DAÑO. Incidente que pudo causar daño pero fue evitado o incidente que ocurrió pero no causó daño"/>
                                        <v-radio value="baj"  label="BAJO. Incidente que causó un daño mínimo al paciente"/>
                                        <v-radio value="mod"  label="MODERADO. Incidente que causó un daño significativo pero no permanente al paciente"/>
                                        <v-radio value="gra"  label="GRAVE. Incidente que tiene como resultado un daño permanente al paciente"/>
                                        <v-radio value="mue"  label="MUERTE. Incidente que ocasionó directamente la muerte del paciente"/>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="dialog=false">
                        Cancelar
                    </v-btn>

                    <!-- El botón para GUARDAR -->
                    <v-btn
                        color="success"
                        :disabled="!formValid"
                        :loading="loadingBtn"
                        @click="guarda"
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props:{
        dialogOpen:Boolean,
        datos:Object,
        dp:Object,
    },

    data:()=>({
        paso:1,
        formValid:false,
        loadingBtn:false,
        nd:{},

        fechaModal:false,
        fechaPicker:'',

        horaModal:false,
        horaPicker:'',

        datosSelect:[
            {value:1, text:"Médico"},
            {value:2, text:"Enfermera"},
            {value:3, text:"Camillero"},
            {value:4, text:"Técnico"},
            {value:5, text:"Personal en formación"},
            {value:99, text:"Otro"},
        ],
        turnos:[
            {value:'mat', text:"Matutino"},
            {value:'ves', text:"Vespertino"},
            {value:'noc', text:"Nocturno"},
        ],

        // De los detalles
        itemsDetalles:[
            {id:1, label:'Sin daño', descripcion:'Incidente que pudo causar daño, pero fue evitado o incidente que ocurrió pero no causó daño', valor:'sin'},
            {id:2, label:'Bajo', descripcion:'Incidente que causó daño mínimo al paciente', valor:'bajo'},
            {id:3, label:'Moderado', descripcion:'Incidente que causó un daño significativo, pero no permanente al paciente', valor:'moderado'},
            {id:4, label:'Grave', descripcion:'Incidente que tiene como resultado dañ permanente al paciente', valor:'grave'},
            {id:5, label:'Muerte', descripcion:'Incidente que ocasionó directamente la muerte del paciente', valor:'Muerte'},
        ],

        // De la selección
        itemsSeleccion:[
            {id:1, label:'Medicamento', model:'clasMedica', items:'selMedicamentos'},
            {id:2, label:'Dispositivos médicos', model:'clasDispositivos', items:'selDispositivos'},
            {id:3, label:'Documentos de expediente', model:'clasDocumentos', items:'selDocumentos'},
            {id:4, label:'Procedimientos médicos', model:'clasProcMedicos', items:'selProcMedicos'},
            {id:5, label:'I.A.A.S.', model:'clasIaas', items:'selIaas'},
            {id:6, label:'Caidas', model:'clasCaidas', items:'selCaidas'},
            {id:7, label:'Tipo de caidas', model:'clasCaidasTipo', items:'selCaidasTipo'},
            {id:8, label:'Patógenos', model:'clasPatogenos', items:'selPatogenos'},
            {id:9, label:'Hemocomponentes', model:'clasHemocomponentes', items:'selHemocomponentes'},
            {id:10, label:'Estudios de Gabinete', model:'clasGabinete', items:'selGabinete'},
            {id:11, label:'Nutrición', model:'clasNutricion', items:'selNutricion'},
        ],
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogOpen
            },
            set(val){
                this.$emit('update:dialogOpen',val)
            },
        },
    },

    methods:{
        cambiaFecha(){
            this.fechaModal=false
            this.nd.fecha = this.moment(this.fechaPicker).format('DD-MM-YYYY')
        },
        async guarda(){
            if (!this.nd.informaFamiliar){
                this.nd.informaFamiliar=false
            }
            this.loadingBtn=true
            let params = {...this.nd}
            params.paciente = this.dp.cip
            params.cama = this.dp.cama
            try{
                let req =await this.$http({
                    url:'/calidad/guardaEventoAdverso',
                    method:'POST',
                    data:params
                })
                this.loadingBtn=false
                if (req.data.estado){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Reporte guardado con número de folio '+req.data.root.folio,
                    })
                    this.dialog = false
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Revise los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingBtn=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    watch:{
        dialog(val){
            if (!val){
                this.$refs.formulario.reset()
                this.paso=1
            }
        },

    },
}
</script>

<style>

</style>