<template>
    <div>
        <v-toolbar dense flat class="grey lighten-3">
            <v-menu
                ref="menuFecha"
                v-model="menuFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        v-on="on"
                    >
                        <v-icon>mdi-calendar-month</v-icon>
                    </v-btn>
                </template>

                    <v-date-picker
                        v-model="pickerFecha"
                        no-title
                        reactive
                        show-current
                        :max="estaFechaMax"
                        locale="es-MX"
                        @change="seleccionaFecha"
                    >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="pickerFecha = new Date().toISOString().substring(0,10)">Hoy</v-btn>
                </v-date-picker>

            </v-menu>
            <b>{{moment(estaFecha).format('dddd MMMM D, YYYY')}}</b>

            <v-spacer/>
            <!-- -------------------------------
                QUITAR PARA ACTIVAR LA HOJA
            <v-menu
                v-if="tieneRol('ROLE_ADMIN')"
                offset-y
            >
            ----------------------------------- -->
            <v-menu
                offset-y
            >

                <template v-slot:activator="{ on }">
                    <v-btn  dark rounded v-on="on" color="blue" :loading="cargaImprimeHno">
                        Imprime HNO
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                        v-for="(item, index) in datosTurnos"
                        :key="index"
                        @click="imprimeHojaHno(item)"
                    >
                        <v-list-item-title :class="item.color+'--text'">{{ item.text }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        @click="imprimeValoracion()"
                    >
                        <v-list-item-title>Valoración inicial</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!--
            <v-btn x-small text rounded outlined @click="imprimeHoja">
                Imprime anterior
            </v-btn>
            -->
        </v-toolbar>

        <v-tabs
            show-arrows
            color="primary"
            v-model="tabEnf"
            slider-size=5
            background-color="grey lighten-3"
            height="60"
            icons-and-text
        >

            <v-tab
                class="caption font-weight-black"
                v-for="itm in tabs"
                :key="itm.id"
            >
                {{itm.label}}
                <v-icon >
                    {{itm.icon}}
                </v-icon>
            </v-tab>

            <v-tabs-items v-model="tabEnf" touchless>

                <v-tab-item
                    v-for="itm in tabs"
                    :key="itm.id"
                    class="mb-1"
                >
                    <component
                        :is="itm.component"
                        :estaFecha="moment(estaFecha).format('DD-MM-YYYY')"
                        :internamiento='esteInternamiento'
                    />
                </v-tab-item>

            </v-tabs-items>

        </v-tabs>

        <imprime-hoja-hno
            :datos="datosImprimeTodos"

            :entradaDialog.sync="imprimeDialog"
            :turno="turnoPrint"
            :estaFecha="moment(estaFecha).format('DD-MM-YYYY')"

            :datosInter="datosInter"
            :datosPac="datosPac"
        />
        <imprime-valora-hno
            :dialogModal.sync="dialogValoracion"

            turno="mat"
            :estaFecha="moment(estaFecha).format('DD-MM-YYYY')"

            :datosInter="datosInter"
            :datosPac="datosPac"
            :datosVal="datosVal"
            :datosValUser="datosValUser"
            :datosValPato="datosValPato"
        />
    </div>
</template>

<script>

export default {
    mounted () {
        //this.estaFecha = new Date()
        //console.log(this.$store.getters['security/estaFecha'])
        if (this.$store.getters['security/estaFecha']){
            this.estaFecha = this.$store.getters['security/estaFecha']
            this.pickerFecha = this.$store.getters['security/estaFecha'].toISOString().substring(0,10)
        } else {
            this.estaFecha = new Date()
            this.pickerFecha = new Date().toISOString().substring(0,10)
        }
    },
    props:{
        internamiento:Number,
    },

    components:{

        'valoracion-inicial': () => import('@/components/hospital/enfe/valinicial/ValoracionInicial'),
        'enfe-somato': () => import('@/components/hospital/enfe/somato/Somatometria'),
        'enfe-valoraciones': () => import('@/components/hospital/enfe/valoraciones/Valoraciones'),
        'enfe-diagnosticos': () => import('@/components/hospital/enfe/diagnosticos/Diagnosticos'),
        'enfe-interv': () => import('@/components/hospital/enfe/interven/intervenciones'),
        'enfe-intravenosa': () => import('@/components/hospital/enfe/intravenosa/TerapiaIntravenosa'),
        'enfe-medicamentos': () => import('@/components/hospital/enfe/medicamentos/KardexMedicamentos'),
        'enfe-ingresos': () => import('@/components/hospital/enfe/ingresos/ControlIngresos'),
        'enfe-nutricion': () => import('@/components/hospital/enfe/nutricion/Nutricion'),
        'enfe-estudios': () => import('@/components/hospital/enfe/estudios/Estudios'),
        'enfe-alta': () => import('@/components/hospital/enfe/alta/Alta.vue'),
        'enfe-procedimientos': () => import('@/components/hospital/enfe/procedimientos/ProcEnf.vue'),
        'enfe-trans': () => import('@/components/hospital/enfe/transf/EnfermeriaTransf.vue'),

        'imprime-hoja-hno': () => import('@/components/hospital/enfe/print/HojaEnfermeriaHno.vue'),
        'imprime-valora-hno': () => import('@/components/hospital/enfe/print/HojaValoracionInicial.vue'),
    },
    data: ()=>({
        tabEnf:0,
        menuFecha:false,
        pickerFecha:'',
        estaFecha:null,
        tabs:[
            {id:0, label:'Inicial', icon:'mdi-account-question-outline', component:'valoracion-inicial'},
            {id:1, label:'Somato', icon:'mdi-chart-line', component:'enfe-somato'},
            {id:2, label:'Escala', icon:'mdi-emoticon-outline', component:'enfe-valoraciones'},
            {id:3, label:'Diagn.', icon:'mdi-view-column-outline', component:'enfe-diagnosticos'},
            {id:4, label:'Interv.', icon:'mdi-text-box-outline', component:'enfe-interv'},
            {id:5, label:'Soluc.', icon:'mdi-water-outline', component:'enfe-intravenosa'},
            {id:6, label:'Medic.', icon:'mdi-needle', component:'enfe-medicamentos'},
            {id:7, label:'Balance', icon:'mdi-scale-balance', component:'enfe-ingresos'},
            {id:8, label:'Alimen.', icon:'mdi-silverware-fork-knife', component:'enfe-nutricion'},
            {id:9, label:'Estudios', icon:'mdi-format-list-checks', component:'enfe-estudios'},
            {id:10, label:'Alta', icon:'mdi-exit-run', component:'enfe-alta'},
            {id:11, label:'Procs.', icon:'mdi-file-tree', component:'enfe-procedimientos'},
            {id:12, label:'Transf.', icon:'mdi-blood-bag', component:'enfe-trans'},
        ],

        datosTurnos:[
            {val:'mat', text: 'Matutino', color:'blue'},
            {val:'ves', text: 'Vespertino', color:'green'},
            {val:'noc', text: 'Nocturno', color:'red'},
        ],

        datosImprimeTodos:[],
        imprimeDialog:false,
        turnoPrint:'',
        cargaImprimeHno:false,

        datosInter:{},
        datosPac:{},

        dialogValoracion:false,
        datosVal:{},
        datosValUser:{},
        datosValPato:[],
    }),
    computed:{
        estaFechaMax(){
            return this.$store.getters['security/estaFechaMax']
        },
        esteInternamiento(){
            if(this.$route.params.id){
                return parseInt(this.$route.params.id)
            } else {
                return parseInt(this.internamiento)
            }
        },
    },
    methods:{
        seleccionaFecha(dt){
            this.menuFecha=false
            let dtA = dt.split('-')
            this.estaFecha = new Date(dtA[0], dtA[1]-1, dtA[2])
        },
        async cargaDatosHoja(turno){
            this.cargaImprimeHno=true
            let params = {
                turno:turno.val,
                internamiento:this.esteInternamiento,
                estaFecha:this.moment(this.estaFecha).format('DD-MM-YYYY')
            }
            try{
                // Ejecuta todas las promesas
                let datosTodos = await Promise.all([
                    await this.$store.dispatch('enfermeria/datosGraficasSomato', params),
                    await this.$store.dispatch('enfermeria/getSignosVitales', params),
                    await this.$store.dispatch('enfermeria/medicamentosAplicados', params),
                    await this.$store.dispatch('enfermeria/getSolucionesTodas', params),
                    await this.$store.dispatch('enfermeria/getBalanceLiq', params),
                    await this.$store.dispatch('enfermeria/getDatosEscalas', params),
                    await this.$store.dispatch('enfermeria/getDatosValoraciones', params),
                    await this.$store.dispatch('enfermeria/getDiagnosticos', params),
                    await this.$store.dispatch('enfermeria/getResultados', params),
                    await this.$store.dispatch('enfermeria/getNutri', params),
                    await this.$store.dispatch('enfermeria/getIntervenciones', params),
                    await this.$store.dispatch('enfermeria/getLab', params),
                    await this.$store.dispatch('enfermeria/getRx', params),
                    await this.$store.dispatch('enfermeria/getPlanAlta', params),

                ])
                //console.log(datosTodos)
                // Asigna los resultados a donde corresponde
                this.datosImprimeTodos.grafica = {...datosTodos[0], ...datosTodos[1]}
                this.datosImprimeTodos.somato = datosTodos[1]
                this.datosImprimeTodos.medicamentos = datosTodos[2]
                this.datosImprimeTodos.soluciones = datosTodos[3]
                this.datosImprimeTodos.liquidos = datosTodos[4]
                this.datosImprimeTodos.escalas = datosTodos[5]
                this.datosImprimeTodos.valoraciones = datosTodos[6]
                this.datosImprimeTodos.datosobj = datosTodos[6]
                this.datosImprimeTodos.diagnosticos = {...datosTodos[7], ...datosTodos[8]},
                this.datosImprimeTodos.firmas = datosTodos[7].usuario,
                this.datosImprimeTodos.nutrimental = datosTodos[9]
                this.datosImprimeTodos.intervenciones = datosTodos[10]
                this.datosImprimeTodos.oxigeno = datosTodos[10]
                this.datosImprimeTodos.sondas = datosTodos[10]
                this.datosImprimeTodos.gabinete = {...datosTodos[11], ...datosTodos[12]}
                this.datosImprimeTodos.alta = {alta:datosTodos[13], inter: this.datosInter}

                //console.log(this.datosImprimeTodos)
                // carga los datos a la vista
                this.turnoPrint=turno.val
                this.imprimeDialog=true
                this.cargaImprimeHno=false
            }catch(err){
                this.cargaImprimeHno=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async imprimeHojaHno(turno){

            this.cargaImprimeHno=true
            try{
                this.cargaDatosHoja(turno)
                let datosInter = await this.$http({
                    url:'/movimientos/datosencuentro',
                    method:'GET',
                    params:{id:parseInt(this.$route.params.id)},
                })
                this.datosInter = datosInter.data.data

                let fecha = this.moment(this.estaFecha).format('DD-MM-YYYY')
                console.log(fecha)

                let fechaTurnoDe = this.moment(fecha+' '+this.$store.state.enfermeria.horarios[turno.val][0], 'DD-MM-YYYY HH:mm')
                let fechaTurnoA = this.moment(fecha+' '+this.$store.state.enfermeria.horarios[turno.val][0], 'DD-MM-YYYY HH:mm').add(this.$store.state.enfermeria.horarios[turno.val].length, 'hours')

                console.log(fechaTurnoDe)
                console.log(fechaTurnoA)

                //let fechaAlta = this.moment(this.datos.alta.dateCreated)


                let datosPac = await this.$http({
                    url:'/pacientes/listV2',
                    method:'POST',
                    params:{id:this.datosInter.cip},
                })
                this.datosPac = datosPac.data


            }catch(err){
                this.cargaImprimeHno=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async imprimeValoracion(){
            // Carga datos internamiento
            let datosInter = await this.$http({
                url:'/movimientos/datosencuentro',
                method:'GET',
                params:{id:parseInt(this.$route.params.id)},
            })
            this.datosInter = datosInter.data.data
            // Carga datos paciente
            let datosPac = await this.$http({
                url:'/pacientes/listV2',
                method:'POST',
                params:{id:this.datosInter.cip},
            })
            this.datosPac = datosPac.data

            let params={internamiento:this.esteInternamiento,}
            let dv = await this.$store.dispatch('enfermeria/getValoracion', params)
            console.log(dv)
            this.datosVal = dv.root
            this.datosValUser = dv.usuario
            this.datosValPato = await this.$store.dispatch('enfermeria/getValoracionPatologicos', params)

            this.dialogValoracion=true

        },
        imprimeHoja(){
            let url = this.$baseUrl+'enfermeria/imprimeHoja/?internamiento='+parseInt(this.esteInternamiento)+'&fecha='+this.moment(this.estaFecha).format('DD-MM-YYYY')
            window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
        },

        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

    },


}
</script>

<style>

</style>