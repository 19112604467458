var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"formulario",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-toolbar',{attrs:{"color":"green","dark":""}},[_c('v-toolbar-title',[(!_vm.datos.id)?_c('span',[_vm._v(" Nuevo reporte ")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1),_c('v-card-text',[_c('span',{staticClass:"text-h6"},[_vm._v("Reporte de eventos adversos")]),_c('v-text-field',{attrs:{"label":"Descripción breve","dense":"","rounded":"","filled":"","rules":[
                        value => !!value || "Requerido",
                    ]},model:{value:(_vm.nd.descripcionBreve),callback:function ($$v) {_vm.$set(_vm.nd, "descripcionBreve", $$v)},expression:"nd.descripcionBreve"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"5","cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"label":"Fecha en que ocurrió","dense":"","rounded":"","filled":"","rules":[
                                            value => !!value || "Requerido",
                                            value => /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo 31-01-2021",
                                        ]},model:{value:(_vm.nd.fecha),callback:function ($$v) {_vm.$set(_vm.nd, "fecha", $$v)},expression:"nd.fecha"}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.fechaModal=true}}},on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.fechaModal),callback:function ($$v) {_vm.fechaModal=$$v},expression:"fechaModal"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.cambiaFecha},model:{value:(_vm.fechaPicker),callback:function ($$v) {_vm.fechaPicker=$$v},expression:"fechaPicker"}})],1)],1)]),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":"Hora","dense":"","rounded":"","filled":"","rules":[
                                            value => !!value || 'Requerido.',
                                            value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                        ]},model:{value:(_vm.nd.hora),callback:function ($$v) {_vm.$set(_vm.nd, "hora", $$v)},expression:"nd.hora"}}),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.horaModal=true}}},on),[_c('v-icon',[_vm._v("mdi-clock")])],1)]}}]),model:{value:(_vm.horaModal),callback:function ($$v) {_vm.horaModal=$$v},expression:"horaModal"}},[_c('v-time-picker',{attrs:{"format":"24hr"},on:{"change":function($event){_vm.horaModal=false}},model:{value:(_vm.nd.hora),callback:function ($$v) {_vm.$set(_vm.nd, "hora", $$v)},expression:"nd.hora"}})],1)],1)]),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-select',{attrs:{"label":"Turno","items":_vm.turnos,"dense":"","rounded":"","filled":"","rules":[
                                        value => !!value || "Requerido",
                                    ]},model:{value:(_vm.nd.turno),callback:function ($$v) {_vm.$set(_vm.nd, "turno", $$v)},expression:"nd.turno"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Lugar en que ocurrió el incidente","dense":"","rounded":"","filled":"","rules":[
                                        value => !!value || "Requerido",
                                    ]},model:{value:(_vm.nd.lugar),callback:function ($$v) {_vm.$set(_vm.nd, "lugar", $$v)},expression:"nd.lugar"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.datosSelect,"label":"Personal implicado","chips":"","deletable-chips":"","dense":"","rounded":"","filled":"","multiple":"","rules":[
                                        value => !!value || "Requerido.",
                                        value => value?value.length > 0:null || "Requerido."
                                    ]},model:{value:(_vm.nd.implicado),callback:function ($$v) {_vm.$set(_vm.nd, "implicado", $$v)},expression:"nd.implicado"}}),_c('v-expand-transition',[(_vm.nd.implicado?_vm.nd.implicado.includes(99):false)?_c('v-text-field',{attrs:{"label":"Otro implicado","dense":"","rounded":"","filled":"","rules":[
                                            value => !!value || "Requerido.",
                                        ]},model:{value:(_vm.nd.implicadoOtro),callback:function ($$v) {_vm.$set(_vm.nd, "implicadoOtro", $$v)},expression:"nd.implicadoOtro"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.datosSelect,"label":"Personal que presencia","chips":"","deletable-chips":"","dense":"","rounded":"","filled":"","multiple":"","rules":[
                                        value => !!value || "Requerido.",
                                        value => value?value.length > 0:null || "Requerido."
                                    ]},model:{value:(_vm.nd.presencia),callback:function ($$v) {_vm.$set(_vm.nd, "presencia", $$v)},expression:"nd.presencia"}}),_c('v-expand-transition',[(_vm.nd.presencia?_vm.nd.presencia.includes(99):false)?_c('v-text-field',{attrs:{"label":"Otro que presencia","dense":"","rounded":"","filled":"","rules":[
                                            value => !!value || "Requerido.",
                                        ]},model:{value:(_vm.nd.precenciaOtro),callback:function ($$v) {_vm.$set(_vm.nd, "precenciaOtro", $$v)},expression:"nd.precenciaOtro"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Descripción detallada del evento","dense":"","rounded":"","filled":"","rules":[
                                        value => !!value || "Requerido.",
                                    ]},model:{value:(_vm.nd.descripcionDetallada),callback:function ($$v) {_vm.$set(_vm.nd, "descripcionDetallada", $$v)},expression:"nd.descripcionDetallada"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Se informa a familiar o paciente"},model:{value:(_vm.nd.informaFamiliar),callback:function ($$v) {_vm.$set(_vm.nd, "informaFamiliar", $$v)},expression:"nd.informaFamiliar"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":"¿Quien proporciona la información?","dense":"","rounded":"","filled":""},model:{value:(_vm.nd.personaProporcionaInfo),callback:function ($$v) {_vm.$set(_vm.nd, "personaProporcionaInfo", $$v)},expression:"nd.personaProporcionaInfo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Gravedad "),_c('v-radio-group',{model:{value:(_vm.nd.gravedad),callback:function ($$v) {_vm.$set(_vm.nd, "gravedad", $$v)},expression:"nd.gravedad"}},[_c('v-radio',{attrs:{"value":"sin","label":"SIN DAÑO. Incidente que pudo causar daño pero fue evitado o incidente que ocurrió pero no causó daño"}}),_c('v-radio',{attrs:{"value":"baj","label":"BAJO. Incidente que causó un daño mínimo al paciente"}}),_c('v-radio',{attrs:{"value":"mod","label":"MODERADO. Incidente que causó un daño significativo pero no permanente al paciente"}}),_c('v-radio',{attrs:{"value":"gra","label":"GRAVE. Incidente que tiene como resultado un daño permanente al paciente"}}),_c('v-radio',{attrs:{"value":"mue","label":"MUERTE. Incidente que ocasionó directamente la muerte del paciente"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.formValid,"loading":_vm.loadingBtn},on:{"click":_vm.guarda}},[_vm._v(" Guardar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }