var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"white--text"},[_vm._v("Ficha de identificación")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("mdi-close-box")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.print}},[_vm._v("Imprimir")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Cerrar")])],1),_c('v-card-text',[_c('v-sheet',[_c('div',{style:({"width":"28","height":"16cm", "border": "1px dotted gray"}),attrs:{"id":"printFichaId"}},[_c('div',{style:({
                            "font-size":"14pt",
                            "line-height": "1",
                        })},[_c('div',{style:({
                            "display": "grid",
                            "grid-template-columns": "20% 50% 30%",
                            "padding":"10px 0px 0px 0px"
                        })},[_c('div',[_c('img',{attrs:{"src":"/images/salud.png","height":"50px"}})]),_c('div',{style:({"text-align":"center"})},[_c('b',[_vm._v("Ficha de identificación del paciente")]),_c('br'),_vm._v(" Jefatura de Enfermería ")]),_c('div',{style:({"text-align":"right"})},[_c('img',{attrs:{"src":"/images/logo-hno-2020.jpg","height":"38px"}})])]),_c('div',[_c('div',{style:({
                                "display": "flex",
                                "padding":"0px 0px 10px 0px",
                            })},[_c('div'),_c('div',{style:(_vm.estilos.campo)},[_c('span',{style:({"font-size":"30pt"})},[_c('b',[_vm._v(" "+_vm._s(_vm.dp.nombres))])])])]),_c('div',{style:({"font-size":"12pt", "padding":"0px 0px 10px 0px","text-align":"center"})},[_vm._v("Nombre")])]),_c('div',{style:({
                            "display": "grid",
                            "grid-template-columns": "48% 27% 25%",
                        })},[_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_vm._v("Fecha de nacimiento:")]),_c('span',{style:(_vm.estilos.campo)},[_c('span',{style:({"font-size":"24pt"})},[_c('b',[_vm._v(_vm._s(_vm.dp.fechaNacimiento))])])])])]),_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_c('br'),_vm._v("Edad:")]),_c('span',{style:(_vm.estilos.campo)},[_vm._v(" "+_vm._s(_vm.dp.edad)+" ")])])]),_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_c('br'),_vm._v("Sexo:")]),_c('span',{style:(_vm.estilos.campo)},[_vm._v(" "+_vm._s((_vm.dp.sexo=='m')?'HOMBRE':'MUJER')+" ")])])])]),_c('div',{style:({
                            "display": "grid",
                            "grid-template-columns": "20% 80%",
                            "padding":"10px 0px 10px 0px",
                        })},[_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt", })},[_vm._v("Grupo Sanguineo:")]),_c('span',{style:({..._vm.estilos.campo, ...{"font-size":"20pt",}})},[_c('b',[_vm._v(" "+_vm._s(_vm.dp.sangre?_vm.dp.sangre:'?')+" ")])])])]),_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_c('br'),_vm._v("Alergias:")]),_c('span',{style:({..._vm.estilos.campo, ...{"font-size":"18pt",}})},[_c('b',[_vm._v(" "+_vm._s(_vm.dp.alergias?_vm.dp.alergias:'NO REGISTRADAS')+" ")])])])])]),_c('div',{style:({
                            "display": "grid",
                            "grid-template-columns": "50% 50%",
                            "padding":"10px 0px 10px 0px",
                        })},[_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_vm._v("Ingreso:")]),_c('span',{style:(_vm.estilos.campo)},[_vm._v(" "+_vm._s(_vm.dp.dateCreated)+" ")])])]),_c('div',[_c('div',{style:({"display": "flex"})},[_c('div',{style:({"font-size":"12pt"})},[_vm._v("Expediente:")]),_c('span',{style:(_vm.estilos.campo)},[_vm._v(" "+_vm._s(_vm.dp.cip)+" ")])])])]),_c('div',{style:({
                            "display": "grid",
                            "grid-template-columns": "40% 20% 40%",
                            "justify-items": "center",
                            "font-size":"10pt",
                            "text-align":"center"
                        })},[_c('div',[_c('div',{style:({"font-size":"12pt"})},[_c('u',[_vm._v("Riesgo de caidas")])]),_c('div',{style:({
                                    "text-align":"center",
                                    "font-size":"10pt",
                                    "display": "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                })},[_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/alto.png","height":"38px"}}),_c('div',[_vm._v("A")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/medio.png","height":"38px"}}),_c('div',[_vm._v("M")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/bajo.png","height":"38px"}}),_c('div',[_vm._v("B")])])]),_c('div',{style:({
                                        "margin":"0px 0px 0px 50px",
                                        "text-align":"LEFT",
                                        "font-size":"7pt",
                                    })},[_vm._v(" A: ALTO. Rojo,"),_c('br'),_vm._v(" M: MEDIO. Amarillo,"),_c('br'),_vm._v(" B: Bajo. Verde,"),_c('br')])]),_c('div'),_c('div',[_c('div',{style:({
                                    "font-size":"10pt",
                                    "text-align":"center"
                                })},[_c('div',[_c('div',{style:({"font-size":"12pt"})},[_c('u',[_vm._v("Riesgo de lesiones por presión")])]),_c('div',{style:({
                                            "text-align":"center",
                                            "font-size":"10pt",
                                            "display": "flex",
                                            "align-items": "center",
                                            "justify-content": "center",
                                        })},[_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/alto.png","height":"38px"}}),_c('div',[_vm._v("A")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/medio.png","height":"38px"}}),_c('div',[_vm._v("M")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/bajo.png","height":"38px"}}),_c('div',[_vm._v("B")])])]),_c('div',{style:({
                                                "margin":"0px 0px 0px 50px",
                                                "text-align":"LEFT",
                                                "font-size":"7pt",
                                            })},[_vm._v(" A: ALTO. Rojo,"),_c('br'),_vm._v(" M: MEDIO. Amarillo,"),_c('br'),_vm._v(" B: Bajo. Verde,"),_c('br')])])])])]),_c('div',{style:({
                            "font-size":"10pt",
                            "text-align":"center"
                        })},[_c('div',{style:({"font-size":"12pt"})},[_c('u',[_vm._v("Riesgo de transmisión")])]),_c('div',{style:({
                                "text-align":"center",
                                "font-size":"10pt",
                                "display": "flex",
                                "align-items": "center",
                                "justify-content": "center",

                            })},[_c('div',{style:({"margin":"5px"})},[_c('img',{attrs:{"src":"/images/estandar.png","height":"38px"}}),_c('div',[_vm._v("E")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/contacto.png","height":"38px"}}),_c('div',[_vm._v("C")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/gotas.png","height":"38px"}}),_c('div',[_vm._v("G")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/aire.png","height":"38px"}}),_c('div',[_vm._v("A")])]),_c('div',{style:({"margin":"10px"})},[_c('img',{attrs:{"src":"/images/protector.png","height":"38px"}}),_c('div',[_vm._v("P")])])]),_c('div',{style:({
                                    "margin":"0px 0px 0px 300px",
                                    "text-align":"LEFT",
                                    "font-size":"7pt",
                                })},[_vm._v(" E: ESTANDAR. Rojo,"),_c('br'),_vm._v(" C: CONTACTO. Amarillo,"),_c('br'),_vm._v(" G: GOTAS. Verde,"),_c('br'),_vm._v(" A: AEREA. Azul,"),_c('br'),_vm._v(" P: PROTECTOR. Gris,"),_c('br')])]),_c('div',{style:({
                            "margin":"0px 10px 10px 10px",
                            "font-size":"8.5pt",
                            "text-align":"right"
                        })},[_c('b',[_vm._v("Impreso")]),_vm._v(" "+_vm._s(_vm.moment(new Date()).format('dddd MMMM D, YYYY'))+" "),_c('b',[_vm._v("por ")]),_vm._v(_vm._s(_vm.$store.getters['security/username'])+" ")]),_c('hr'),_c('div',{style:({
                            "font-size":"7pt",
                            "width":"6.5cm",
                            "height":"1.5cm",
                            "border": "1px dotted gray",
                            "padding":"3px"
                        })},[_c('div',[_c('div',{style:({"float": "left", "width":"5.1cm",})},[_c('B',[_vm._v("NOMBRE: ")]),_vm._v(_vm._s(_vm.dp.nombres)),_c('br'),_c('b',[_vm._v("F. DE NAC.: ")]),_vm._v(_vm._s(_vm.dp.fechaNacimiento)),_c('br')],1),_c('div',{style:({"float": "left", "margin": "0 auto"})},[_c('img',{attrs:{"src":"/images/logo-hno-peque.jpg","width":"33px"}})])]),_c('div',{style:({"float": "left"})},[_c('B',[_vm._v("SEXO: ")]),_vm._v(_vm._s((_vm.dp.sexo=='m')?'HOMBRE':'MUJER')),_c('br'),_c('B',[_vm._v("EXPEDIENTE: ")]),_vm._v(_vm._s(_vm.dp.cip)),_c('br')],1)])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }