<template>
    <div>
        <div v-if="loadingPad">
            <v-skeleton-loader
                class="mx-auto"
                type="article, article"
            ></v-skeleton-loader>

        </div>


        <div v-if="!loadingPad">
            
            <v-toolbar dense flat>
                <v-btn outlined color="success" small text @click="dialogAgrega=true">
                    <v-icon>mdi-plus</v-icon> Agregar
                </v-btn>
                <v-spacer/>
                <v-switch
                    class="mt-3"
                    v-model="todosPad"
                    label="Todos"
                    @change="getPade"
                ></v-switch>
            </v-toolbar>

            <v-alert class="mx-2" type="info" text border="left" v-if="procedimientos.length == 0" dense>
                No hay procedimientos. Por favor 
                <v-btn outlined color="primary" text @click="dialogAgrega=true">
                    AGREGAR
                </v-btn>
            </v-alert>

            <v-list>
                <v-list-item-group v-model="selection">
                    <v-list-item three-line v-for="itm in procedimientos" :key="itm.id">
                        
                        <v-list-item-avatar v-if="itm.internamiento != internamiento">
                            <v-icon color="orange">mdi-alert-circle</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title style="white-space: pre-line;">
                                {{itm.procedimientoNombreComun}}
                            </v-list-item-title>

                            <v-list-item-subtitle v-if="itm.procedimientoCode" style="white-space: pre-line;">
                                ({{itm.procedimientoCode}}) {{itm.procedimiento}}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle style="white-space: pre-line;">
                                <span >Registrado {{itm.dateCreated}} por {{itm.creado}}</span>
                                <span v-if="itm.fechaFinalizacion" class="red--text"><br/>Finalizado {{itm.fechaFinalizacion}} por {{itm.finalizado}}</span>
                            </v-list-item-subtitle>
                        <hr>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
        
        <agrega-proc
            :dialogAgrega.sync='dialogAgrega'
            :paciente='paciente'
            :internamiento="internamiento"
            @agregado="getPade"
        />
        
    </div>
</template>

<script>
//import CatalogoDiagnostico from '@/components/diagnosticos/catalogoDiagnostico'
import AgregaProc from '@/components/procedimientos/agregaProc'
export default {

    mounted(){
        this.getPade()
    },

    components:{
        //'catalogo-diagnostico':CatalogoDiagnostico,
        'agrega-proc':AgregaProc,
    },

    props:{
        paciente:{
            type:Number
        },
        estePade:{
            type:Object
        },
        internamiento:{
            type:Number
        }
        
    },

    data:()=>({

        validaDiagForm:false,

        selection:[],
        loadingPad: false,
        procedimientos:[],
        total:0,
        
        padecimientoDetalles:{},

        todosPad:false,

        dialogDetalles:false,

        dialogAgrega:false, //Para lo que agrega inline

        // Form para agregar padecimientos
        datosPad:{
            padecimiento:null,
        },

    }),


    methods:{

        padDetalle(data){
            this.padecimientoDetalles=(data)
            this.dialogDetalles = true
        },

        async getPade(){

            this.loadingPad = true
            let params = {internamiento:this.internamiento}

            if (this.todosPad){
                params.todos = true
            }
            
            try{
                let pd = await this.$http({
                    url:'/hospital/listaProcedimientos2',
                    params:params
                })

                this.loadingPad = false

                this.procedimientos = pd.data.root
                this.total = pd.data.total
                
            } catch(err){
                this.loadingPad = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        

        
    },

    watch:{
        
    },
    
}
</script>

<style>

</style>