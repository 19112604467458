<template>
    <div>
        <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Cirugías programadas</v-toolbar-title>
            <v-spacer/>
            <v-btn icon small @click="carga()">
                <v-icon>
                    mdi-autorenew
                </v-icon>
            </v-btn>
            <v-btn icon small v-if="permiteNuevo" @click="abreNuevaCirugia">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="datos"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loadingDatosTodos"

            :server-items-length='totalRegistros'
            :options.sync="optionsTable"
        >

            <template v-slot:[`item.urgencia`]="{ item }">
                <v-icon color="red" v-if="item.urgencia">mdi-flag</v-icon>
            </template>

            <template v-slot:[`item.sala`]="{ item }">
                <div>{{item.fechaAsignada}}</div>
            </template>

            <template v-slot:[`item.pacienteNombre`]="{ item }">
                <div>Proc: {{item.procedimiento}}</div>
                <div>Diag: {{item.padecimiento}}</div>
            </template>

            <template v-slot:[`item.staff`]="{ item }">
                <div>Médico: {{getIntegrantes(item.staff, 1).staff}}</div>
                <div>Anestesio: {{getIntegrantes(item.staff, 2).staff}}</div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getStatus(item.status).color" label @click="muestraAlarma(item)">
                    <div class="white--text">{{getStatus(item.status).text}}</div>
                </v-chip>
            </template>

            <template v-slot:[`item.view`]="{ item }">
                <v-btn icon small @click="cargaCirugiaPaquete(item)" v-if="!escondePaqueteoperes"><v-icon>mdi-note-text</v-icon></v-btn>
                <v-btn icon small><v-icon>mdi-magnify</v-icon></v-btn>
            </template>

            <!--
                <template v-slot:[`item.view`]="{ item }">
                    <v-icon @click="cargaDatos(item)">mdi-magnify</v-icon>
                </template>
                <template v-slot:[`item.areaNom`]="{ item }">
                    <div>{{item.areaNom}}</div>
                    <div>({{item.servicioNom}})</div>
                </template>

                <template v-slot:[`item.veInter`]="{ item }">
                    <v-icon @click="$router.push({path:`/hospital/interna/`+item.id})">mdi-folder</v-icon>
                </template>
            -->

        </v-data-table>
        <ciru-nueva
            :paciente="dp.pacienteId"
            :cirugia="estaCirugia"
            :dialogModal.sync="ciruModal"
            :internamiento="internamiento"
            :consulta="consulta"
            @guardado="carga()"
        ></ciru-nueva>
        <ciru-paquete
            :dialogModal.sync="ciruPaquete"
            :cirugia="estaCirugia"
        ></ciru-paquete>

    </div>
</template>

<script>
export default {
    components:{
        'ciru-nueva':()=>import('@/views/quirofano/QuirofanoNuevaCirugia'),
        'ciru-paquete':()=>import('@/views/quirofano/QuirofanoPaqueteoperes'),
    },
    created(){
        //this.carga()
    },
    props:{
        dp:Object,
        tab: Number,
        esteTab:Number,
        permiteNuevo: Boolean,

        internamiento:Number,
        consulta:Number,
        escondePaqueteoperes:Boolean,
    },
    data:()=>({
        params:{},
        headers:[
            {text:'Urg', value:'urgencia', align:'center'},
            {text:'Fecha', value:'sala', width:'100px', align:'center'},
            {text:'Procedimiento', value:'pacienteNombre'},
            {text:'Folio', value:'folio'},
            {text:'Médico', value:'staff'},
            {text:'Estado', value:'status', width:'10%', align:'center'},
            {text:'', value:'view', width:'100px'},
        ],

        datos:[],
        loadingDatosTodos:false,
        optionsTable:{},
        totalRegistros:0,
        estaCirugia:null,

        ciruModal:false,
        ciruPaquete:false,
    }),

    methods:{
        abreNuevaCirugia(){
            this.cirugia=null
            this.ciruModal=true
        },
        abreDetalleCirugia(itm){
            this.cirugia=itm.id
            this.ciruModal=true
        },
        async carga(){

            //this.params={...this.optionsTable}
            this.params.paciente = this.dp.pacienteId
            this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.params.limit=this.optionsTable.itemsPerPage
            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/cirugia/listCirugia',
                    method:'GET',
                    params: this.params
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cargaCirugiaPaquete(itm){
            this.estaCirugia=itm
            this.ciruPaquete=true
        },
        getIntegrantes(datos, tipo){
            //1: Médico, 2: Anestesiólogo, 3: Ayudante, 4: Interumentista, 5: Circulante, 99: otros 
            let persona = datos.find(el=>el.tipoStaff==tipo)
            return persona?persona:{staff:'?'}
        },
        getStatus(tipo){
            let sta = {color:'black', text:'?'}
            switch (tipo){
                // 1: solicitada, 2: Agendada, 3: En sala, 4; En recuperación, 5: Salió, 90: Reagendada, 99: cancelada,
                case 1:
                    sta.text="Solicitada",
                    sta.color="purple"
                    break
                case 2:
                    sta.text="Agendada",
                    sta.color="blue"
                    break
                case 3:
                    sta.text="En sala",
                    sta.color="orange"
                    break
                case 4:
                    sta.text="En recuperación",
                    sta.color="orange"
                    break
                case 5:
                    sta.text="Salió",
                    sta.color="green"
                    break
                case 90:
                    sta.text="Reagendada",
                    sta.color="red"
                    break
                case 99:
                    sta.text="Cancelada",
                    sta.color="black"
                    break
            }
            return sta
        },
        muestraAlarma(itm){
            //console.log(itm)
            let titulo
            let texto

            switch (itm.status){
                case 1:
                    titulo = "Solicitada"
                    texto = 'Aún no tiene datos disponibles'
                    break
                case 2:
                    titulo = "No procesada"
                    texto = 'Aún no se recibe este paciente'
                    break
                case 3:
                    titulo = "En sala"
                    texto = 'Recibido '+itm.horaLlegada
                    break
                case 5:
                    titulo = "Terminada"
                    texto = 'Salió el '+itm.horaSalida
                    break
                case 90:
                    titulo = "REAGENDADA"
                    texto = itm.motivoReagenda
                    break
                case 99:
                    titulo = "CANCELADA"
                    texto = itm.motivoCancela
                    break

            }

            this.$swal.fire({
                icon: 'info',
                title: titulo,
                text: texto,
            })
        }
    },

    watch:{
        tab(){
            if(this.tab == this.esteTab){
                this.carga()
            }
        },
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.carga()
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>