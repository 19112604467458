<template>
    <div>
        <v-row no-gutters>
            <!-- <v-col cols="5"> -->
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <v-sheet elevation="1">
                    <v-toolbar dense flat dark color="blue">
                        <v-toolbar-title>
                            <v-toolbar-title>Padecimientos</v-toolbar-title>
                        </v-toolbar-title>
                    </v-toolbar>

                    <pade-todos
                        :paciente="datosInternamiento.cip"
                        :estePade.sync="estePade"
                    />

                </v-sheet>
            </v-col>


            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <v-sheet elevation="1">
                    <v-toolbar dense flat dark color="blue">
                        <v-toolbar-title>
                            <v-toolbar-title>Procedimientos</v-toolbar-title>
                        </v-toolbar-title>
                    </v-toolbar>
                    
                    <proc-todos
                        :paciente="datosInternamiento.cip"
                        :estePade.sync="estePade"
                        :internamiento="internamiento"
                    />

                </v-sheet>
            </v-col>
            <!--
            <v-col cols="2" >
                <v-toolbar dense flat dark color="blue">
                </v-toolbar>

                <div class="d-flex justify-center">
                    <v-btn icon color="green" class="my-10" :disabled="permitePasar">
                        <v-icon x-large>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>

            </v-col>

            <v-col cols="5">
                <v-toolbar dense flat dark color="blue">
                    <v-toolbar-title>
                        <v-toolbar-title>Este internamiento</v-toolbar-title>
                    </v-toolbar-title>
                </v-toolbar>
                <pade-interna
                    :internamiento="internamiento"
                />
            </v-col>
                -->
        </v-row>
    
        

        <!-- <v-sheet elevation="2" class="mt-2"> -->


        <!--</v-sheet> -->

        
    </div>
</template>

<script>
import PadeTodos from '@/components/diagnosticos/diagTodos'
import ProcTodos from '@/components/procedimientos/procTodos'
//import PadeInterna from '@/components/diagnosticos/diagInterna'
export default {
    components:{
        PadeTodos,
        ProcTodos,
        //PadeInterna,
    },
    props:{
        internamiento:{
            type:Number
        },
        datosInternamiento:{
            type: Object,
        },
    },
    data:()=>({
        estePade:{},
    }),
    mounted(){
        //console.log(this.datosInternamiento)
    },
    computed:{
        permitePasar(){
            if (Object.keys(this.estePade).length === 0){
                return true
            } else {
                if (this.estePade.fechaFinalizacion){
                    return true
                } else {
                    return false
                }
            }
        }
    },

}
</script>

<style>

</style>