<template>
    <div>
        <div class="d-flex justify-center mt-3">
            <v-menu offset-y >
                <template v-slot:activator="{ on }">
                    <v-btn class="ml-5" rounded outlined color="green" v-on="on" @click="cargaTipoNota" :disabled="Boolean(nuevoTipoNota)" v-show="permiteAgrega">
                        <v-icon  left>mdi-file-plus-outline</v-icon> Nueva nota
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item
                        class="my-n2"
                        v-for="item in tipoNota"
                        :key="item.id"
                        @click="nuevaNota(item)"
                        dense
                    >
                        <v-list-item-title>
                            {{ item.tipoNota }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!--
            <v-btn class="ml-5" rounded outlined color="green" @click="nuevaNota({id:2, tipoNota:'Urgencias'})" :disabled="Boolean(nuevoTipoNota)">
                <v-icon  left>mdi-file-plus-outline</v-icon> Nueva nota
            </v-btn>
            -->


            <v-select
                :items="tipoNota"
                v-model="tipoNotaSelect"
                item-text="tipoNota"
                item-value="id"
                filled
                outlined
                rounded
                clearable
                dense
                hide-details
                label="Filtrar notas"
                class="px-5"
                @focus="cargaTipoNota"
                @change="selectTipoNota"
            ></v-select>

        </div>

        <v-expand-transition>
            <div class="blue lighten-5 mx-5 mt-2 pa-2" v-if="nuevoTipoNota">
                <span class="title">Nueva nota: </span> <span class="subtitle-1">{{nuevoTipoNotaNombre}}</span>

                <editor
                    v-model="nota"
                    api-key="5ao0kvhebp3vinnv26st7ce6tgkpakhs1xa30yiwt7w6qrc4"
                    :init="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'lists link',
                        ],
                        toolbar:
                        'bold italic|bullist numlist'
                    }"
                    />

                <div class="d-flex flex-row">
                    <v-spacer/>
                    <v-btn
                        color="error"
                        text
                        @click="cancela"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="success"

                        :loading="loadingNota"
                        @click="guarda"
                    >
                        Guardar
                    </v-btn>
                </div>
            </div>
        </v-expand-transition>

        <div v-if="cargando">
            <v-skeleton-loader
                class="mx-auto"
                type="article, article"
            ></v-skeleton-loader>

        </div>
        <v-list v-if="!cargando">
            <v-alert type="error" v-if="!internamiento && !paciente && !cargando" class="d-block mx-5">
                Parámetros no configurados.
            </v-alert>
            <v-alert
                v-if="total==0"
                class="d-block mx-5"
                type="warning"
                border="left"
                colored-border
                elevation="2"
            >
                No hay notas que mostrar.
            </v-alert>

            <v-list-item
                v-for="(nota, id) in datos"
                :key="id"
                class="d-block"
            >
                <v-toolbar
                    color="light-blue"
                    dark
                    dense
                    flat
                >
                <v-toolbar-title>{{nota.cama}}</v-toolbar-title>
                    <v-spacer/>
                    {{nota.dateCreated}}
                    <v-icon class="pl-2" v-if="permiteImprimir" @click="muestraImprime(nota.id)">mdi-printer</v-icon>
                </v-toolbar>

                <v-sheet class="mb-2 pa-3 d-block" elevation="1">

                    <div class="text-right subtitle-1 mb-2">Tipo de nota: <span class="font-weight-black">{{nota.tipoNota}}</span></div>

                    <span
                        class="text-justify"
                        elevation="2"
                        v-html="nota.nota"
                    />

                    <v-divider/>
                    <div class="text-right">
                        <span class="subtitle-2 font-weight-black">{{nota.medico.nombres}} {{nota.medico.apellidoPaterno}} {{nota.medico.apellidoMaterno}}</span><br/>
                        <span>{{nota.medico.especialidad}} ({{nota.medico.cedula}})</span><br/>
                        <span><b>CURP:</b> {{nota.medico.curp}}</span>
                    </div>
                </v-sheet>
            </v-list-item>


        </v-list>

        <div v-if="!cargando">
            <v-pagination
                circle
                v-model="page"
                :length="pageLength"
                total-visible="7"
                @input="cargaNota"
            ></v-pagination>

            <div class="text-center caption">
                <b>{{pageDe}}</b> a <b>{{pageA}}</b> de <b>{{total}}</b>
            </div>
        </div>

        <!-- Dialog para imprimir -->
        <v-dialog
            width="350"
            v-model="dialogImprime"
        >

            <v-card>
                <v-form
                    v-model="validaImprime"
                >
                    <v-card-title
                        class="headline blue"
                        primary-title
                    >
                        <span class="white--text">Imprime NOTA</span>
                    </v-card-title>

                    <v-card-text class="pa-5">
                        <v-text-field
                            v-model="altura"
                            type="number"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => value >= 5 || 'Este número no es válido',
                                value => value <= 23 || 'Este número no es válido',
                            ]"
                            label="Altura"

                        />
                        <v-checkbox
                            v-model="encabezado"
                            label="Encabezado"
                        >
                        </v-checkbox>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            @click="imprimeNota"
                            :disabled="!validaImprime"
                        >
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    mounted: function(){
        this.cargaNota()
    },

    components:{
        Editor
    },

    props:{
        internamiento:{
            type:Number,
        },
        datosInternamiento:{
            type:Object,
        },
        paciente:{
            type:Number,
        },
        recargaNotas:{
            type:Boolean,
        },
        permiteAgrega:{
            type:Boolean,
            default: true,
        },
        permiteImprimir:{
            type:Boolean,
            default: true,
        },
        datoNotaExterna:{
            type:Object
        }

    },


    data: ()=>({
        overNote:false,
        dialogImprime:false,
        validaImprime:false,
        estaNota:null,

        altura:5,
        encabezado:true,

        tipoNotaSelect: null,

        nuevoTipoNota:null,
        nuevoTipoNotaNombre:'',
        nuevaNotaField:false,
        loadingNota:false,

        nota:'',
        customToolbar: [["bold", "italic"], [{ list: "ordered" }, { list: "bullet" }]],

        page:1,
        cargando:true,
        datos:{},
        total:null,
        params:{},
        tipoNota:[],

        padecimientosNota:[],
        cadenaPads:'',

    }),

    methods:{
        async cargaNota (){
            if (this.internamiento){
                this.params.internamiento = this.internamiento
            }

            if (this.paciente){
                this.params.paciente = this.paciente
                this.params.cip = this.paciente
            }
            this.cargando = true
            this.params.page = this.page
            this.params.start = (this.page -1) * 10
            this.params.limit = 10

            try{
                let req = await this.$http({
                    method:'GET',
                    url: 'hospital/listanotaencuentro',
                    params: this.params,
                })
                this.cargando = false
                this.datos= req.data.root
                this.total = req.data.total
            } catch(err){
                this.cargando = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cargaTipoNota: function(){
            let me = this;

            this.$http.get('catalogos/tipoNotas',{
            })
            .then(function (response) {
                me.tipoNota = response.data
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        async guarda(){
            this.loadingNota = true
            // Quita los <P> de la cadena
            let notaFinal = this.nota

            notaFinal = notaFinal.replace(/<p>/g,'')
            notaFinal = notaFinal.replace(/<\/p>/g,'<br/>')

            //console.log(notaFinal)
            let params = {
                internamiento: this.internamiento,
                tipoNota: this.nuevoTipoNota,
                nota:notaFinal
            }

            try{
                let no = await this.$http({
                    method:'POST',
                    url:'/hospital/createnotas',
                    params:params,
                })

                if (no.data.estado == 'true'){
                    this.loadingNota = false
                    this.nota = ''
                    this.nuevoTipoNota = null
                    this.cargaNota()
                    this.$emit('update:datoNotaExterna',{})
                } else {
                    this.loadingNota = false
                    console.log(no.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.loadingNota = false
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSigno = false
            }
            //console.log('guardando')
            //console.log(this.nota)
            this.nota = ''
            this.nuevoTipoNota = null


        },
        cancela(){
            this.nuevaNotaField=false
            this.nota = ''
            this.nuevoTipoNota = null
            this.$emit('update:datoNotaExterna',{})
        },

        async nuevaNota(nota){

            try{
                let req = await this.$http({
                    url:'/hospital/listaPadecimientos',
                    method:'GET',
                    params:{internamiento:this.internamiento}
                })

                //console.log(req)

                this.cadenaPads=''
                this.padecimientosNota=[]
                if (req.data.total >0){
                    req.data.root.forEach(itm => {
                        this.padecimientosNota.push(itm)
                        this.cadenaPads = this.cadenaPads+itm.padecimientoNombreComun+'<br/>'
                    })
                }
                //console.log(this.padecimientosNota)
                this.nuevoTipoNota = nota.id
                this.nuevoTipoNotaNombre = nota.tipoNota
                this.nuevaNotaField=true
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        muestraImprime(nota){
            //console.log(nota)
            this.estaNota = nota
            this.dialogImprime = true
        },

        imprimeNota(){
            this.dialogImprime = false
            let url = this.$baseUrl+'hospital/imprimenota/?id='+this.estaNota+'&se='+this.altura+'&en='+this.encabezado
            window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
            //http://localhost:9999/secah2015/hospital/imprimeindicaciones?id=177736&se=9&en=true

        },

        selectTipoNota(id){
            this.tipoNotaSelect = id
            this.params.tipoNota = id
            this.page = 1
            this.cargaNota()
        },
    },

    computed: {
        pageLength: function(){
            return (Math.ceil(this.total / 10));
        },
        pageDe: function(){
            let pd = 0
            if (this.total > 0) {
                pd = ((this.page - 1) * 10)+1
            }
            return pd
        },
        pageA: function(){
            let pa = this.page * 10
            if (pa > this.total ){
                pa = this.total
            }
            return (pa)
        },
    },



    watch:{
        recargaNotas(val){
            if (val){
                this.cargaNota()
                this.$emit('update:recargaNotas',false)
            }
        },
        internamiento: function(nv){
            this.params={
                'internamiento':nv
            }
            this.cargaNota()
        },
        paciente: function(nv){
            this.params={
                'paciente':nv
            }
            this.cargaNota()
        },
        datoNotaExterna(val){
            if (val.id){
                this.nuevoTipoNota = val.id
                this.nuevoTipoNotaNombre = val.tipoNota
                //{id:2, tipoNota:'Urgencias'}
            } else {
                this.nuevoTipoNota = null
                this.nuevoTipoNotaNombre = val.tipoNota
            }
        },
        nuevoTipoNota(val){

            //let cadena = ''
            let nota = ''

            let machoteNota ='<b>PROBLEMAS</b><br>'+
                this.cadenaPads+'<br>'+
                '<b>PROBLEMAS REMITIDOS</b><br><br>'+
                '<b>SUBJETIVO</b><br><br>'+
                '<b>OBJETIVO</b><br><br>'+
                '<b>ANÁLISIS</b><br><br>'+
                '<b>PLAN DE MANEJO</b><br><br>'+
                '<b>INTERPRETACIÓN DE ESTUDIOS</b><br><br>'+
                '<b>PRONÓSTICO PARA LA VIDA</b><br><br>'+
                '<b>GUÍAS DE PRÁCTICA CLÍNICA UTILIZADAS</b><br><br>';

            switch (parseInt(val)){
                // Ingreso
                case 1:
                    nota = '<b>MOTIVO DE INGRESO HOSPITALARIO</b><br><br>'+machoteNota;
                    break
                // Urgencias
                case 2:
                    nota = '<b>MOTIVO DE LA CONSULTA</b><br/><br>'+
                        '<b>PACIENTE ENVIADO A</b><br/><br>'+
                        '<b>SUBJETIVO</b><br/><br>'+
                        '<b>OBJETIVO</b><br/><br>'+
                        '<b>ANÁLISIS</b><br/><br>'+
                        '<b>PLAN DE MANEJO</b><br/><br>'+
                        '<b>ESTADO MENTAL DEL PACIENTE</b><br/><br>'+
                        '<b>PROCEDIMIENTOS REALIZADOS DURANTE LA ESTANCIA</b><br/><br>'+
                        '<b>INTERPRETACIÓN DE ESTUDIOS</b><br/><br>'+
                        '<b>PRONÓSTICO PARA LA VIDA</b><br/><br>'+
                        '<b>GUÍAS DE PRÁCTICA CLÍNICA UTILIZADAS</b><br/><br>'
                    break
                    // Evolución
                    case 3:
                        nota = machoteNota
                        break
                    // Referencia/contrareferencia
                    case 4:

                        this.$swal.fire({
                            icon: 'info',
                            title: 'No usar para cambio de área',
                            html: '<p>Esta nota <b>SÓLO</b> deberá usarse cuando el paciente sea <b>REFERIDO A OTRA UNIDAD.</b></p><br/>'+
                                    '<p>Para cambio de área, use una nota de evolución especificando CAMBIO DE ÁREA</p>',
                        })

                        nota = '<b>ENVIADO A</b><br><br>'+machoteNota
                        break
                    //  Interconsulta
                    case 5:
                        nota = '<b>ESPECIALIDAD</b><br>'+
                            '<b>MOTIVO DE LA INTERCONSULTA</b><br><br>'+machoteNota
                        break
                    // Pre operatoria
                    case 6:
                        nota = '<b>FECHA DE LA CIRUGÍA A REALIZAR</b><br><br>'+
                            '<b>DIAGNÓSTICO PRE OPERATORIO</b><br><br>'+
                            '<b>PLAN QUIRÚRGICO</b><br><br>'+
                            '<b>RIESGO QUIRÚRGICO (abusos, dependencias, etc)</b><br><br>'+
                            '<b>CUIDADOS Y PLAN TERAPÉUTICO PREOPERATORIO</b><br><br>'+ machoteNota
                        break
                    // Pre anestésica
                    case 7:
                        nota ='<b>PROBLEMAS</b><br>'+
                            this.cadenaPads+'<br>'+
                            '<b>SUBJETIVO</b><br><br>'+
                            '<b>OBJETIVO</b><br><br>'+
                            '<b>ANÁLISIS (riesgo anestésico, etc.)</b><br><br>'+
                            '<b>PLAN DE MANEJO (anestesia a utilizar, medicamentos, etc.)</b><br><br>'+
                            '<b>INTERPRETACIÓN DE ESTUDIOS</b><br><br>'+
                            '<b>PRONÓSTICO PARA LA VIDA</b><br><br>'+
                            '<b>GUÍAS DE PRÁCTICA CLÍNICA UTILIZADAS</b><br><br>'
                        break
                    // Post operatoria
                    case 8:
                        nota = '<b>OPERACIÓN PLANEADA</b><br><br>'+
                            '<b>OPERACIÓN REALIZADA</b><br><br>'+
                            '<b>DIAGNÓSTICO POST OPERATORIO</b><br><br>'+
                            '<b>DESCRIPCOÓN DE LA TÉCNICA QUIRÚRGICA</b><br><br>'+
                            '<b>HALLAZGOS TRANSOPERATORIOS</b><br><br>'+
                            '<b>REPORTE DEL CONTEO DE GASAS Y COMPRESAS</b><br><br>'+
                            '<b>INCIDENTES Y ACCIDENTES</b><br><br>'+
                            '<b>CUANTIFICACIÓN DE SANGRADO (en caso de presentarse)</b><br><br>'+
                            '<b>RESULTADO DE INTERPRETACIÓN DE ESTUDIOS TRANS OPERATORIOS</b><br><br>'+
                            '<b>ESTADO POST QUIRÚRGICO INMEDIATO</b><br><br>'+
                            '<b>PLAN DE MANEJO Y TRATAMIENTO POST OPERATORIO INMEDIATO</b><br><br>'+
                            '<b>ENVIO DE PIEZAS O BIOPSIAS QUIRÚRICAS PARA EXAMENES</b><br><br>'+
                            '<b>AYUDANTE, INSTRUMENTISTA, ANESTESIÓLOGO Y CIRCULANTE</b><br><br>'
                        break
                    // Post anestésica
                    case 9:
                        nota = '<b>TÉCNICA ANESTÉSICA</b><br><br>'+
                            '<b>TIPO DE MONITORIZACIÓN</b><br><br>'+
                            '<b>SIGNOS VITALES TRANS OPERATORIOS</b><br><br>'+
                            '<b>MEDICAMENTOS UTILIZADOS</b><br><br>'+
                            '<b>CUANTIFICACIÓN DE SANGRE Y SOLUCIONES UTILIZADAS</b><br><br>'+
                            '<b>DURACIÓN DE LA ANESTESIA</b><br><br>'+
                            '<b>INCIDENTES Y ACCIDENTES ATRIBUIBLES A LA ANESTESIA</b><br><br>'+
                            '<b>ESTADO CLÍNICO AL EGRESO DEL QUIRÓFANO</b><br><br>'+
                            '<b>PLAN DE MANEJO Y TRATAMIENTO INMEDIATO</b><br><br>'
                        break
                    // Transfusión
                    case 10:
                        nota = '<b>CANTIDAD E IDENTIFICACIÓN DE UNIDADES O COMPONENTES TRANSFUNDIDOS;</b><br><br>'+
                            '<b>HORA DE INICIO: </b> <br><br>'+
                            '<b>SIGNOS VITALES AL INICIO:</b><br>'+
                            'F.C.: F.R.: T.A.: <br>'+
                            '<b>HORA DE FINALIZACIÓN: </b><br><br>'+
                            '<b>SIGNOS VITALES AL FINAL:</b><br>'+
                            'F.C.: F.R.: T.A.: <br>'+
                            '<b>COMPLICACIONES Y SU MANEJO</b><br><br>'+
                            '<b>MEDICO RESPONSABLE</b><br><br>'+
                            '<b>ENFERMERA RESPONSABLE</b><br><br>'

                        break
                    // Egreso
                    // OBTENER LOS DATOS DEL EGRESO
                    case 11:
                        this.$swal.fire({
                            icon: 'info',
                            title: 'Sólo para egreso',
                            html: '<p>Esta nota <b>SÓLO</b> deberá usarse cuando el paciente <b>EGRESE DEL HOSPITAL.</b></p><br/>'+
                                    '<p>Para cambio de área, use una nota de evolución especificando CAMBIO DE ÁREA</p>',
                        })
                        nota ='<b>FECHA DE ingreso:</b>'+ this.datosInternamiento.dateCreated+'<br/><br>'+
                            '<b>FECHA DE EGRESO:</b> ----------INGRESAR FECHA DE EGRESO------------<br/><br>'+
                            '<b>DIAGNÓSTICO DE INGRESO</b><br><br>'+
                            '<b>DIAGNÓSTICOS FINALES</b><br>'+this.cadenaPads+'<br><br>'+
                            '<b>DIAGNÓSTICOS REMITIDOS</b><br><br>'+
                            '<b>FECHA Y HORA DE PROCEDIMIENTOS REALIZADOS (en caso de aplicar)</b><br><br>'+
                            '<b>¿INGRESO POR LA MISMA PATOLOGIA EN EL AÑO? (SI/NO)</b><br><br>'+
                            '<b>MOTIVO DE EGRESO (voluntaria, mejoría, máximo beneficio)</b><br><br>'+
                            '<br><b>RESUMEN</b><br><br>'+
                            '<b>PROBLEMAS CLINICOS PENDIENTES</b><br><br>'+
                            '<b>MANEJO, TRATAMIENTO Y RECOMENDACINES</b><br><br>'+
                            '<b>ATENCIÓN A LOS SIGUIENTES FACTORES DE RIESGO</b><br><br>'+
                            '<b>PRONÓSTICO PARA LA VIDA</b><br><br>'
                        break
                    // Enfermeria
                    case 12:
                        nota = '<b>ANOTACIONES ADICIONALES A LOS REGISTROS DE ENFERMERÍA</b><br>'
                        break
                    // Quimioterapia
                    case 13:
                        nota = '<b>PROBLEMAS:<br></b>'+this.cadenaPads+'<br>'+
                            '<b>SIGNOS VITALES AL INICIO:</b><br>'+
                            'F.C.: F.R.: T.A.: Temp.: SO2:<br>'+
                            '<b>SIGNOS VITALES AL EGRESO:</b><br>'+
                            'F.C.: F.R.: T.A.: Temp.: SO2:<br>'+
                            '<b>MÉDICO QUE INDICA LA QUIMIOTERAPIA:</b><br><br>'+
                            '<b>MEDICAMENTOS APLICADOS (medicamento, dosis, vía, frecuencia):</b><br><br>'+
                            '<b>¿REQUIRIÓ VALORACIÓN MÉDICA?:</b><br><br>'+
                            '<b>¿RECIBIÓ TRATAMIENTO ALTERNO?:</b><br><br>'+
                            '<b>NOMBRE CÉDULA Y FIRMA DE MÉDICO QUE VALORA:</b><br><br>'+
                            '<b>NOTA:</b><br><br>'+
                            '<b>GUIA DE PRÁCTICA CLÍNICA UTILIZADA:</b><br><br>'
                        break
                    // Int. de Estudios
                    case 14:
                        nota = '<b>FECHA Y HORA DE LA REALIZACIÓN DEL ESTUDIO</b><br><br>'+
                            '<b>SOLICITADO POR</b><br><br>'+
                            '<b>ESTUDIO SOLICITADO</b><br><br>'+
                            '<b>PROBLEMAS CLÍNICOS EN ESTUDIO</b><br><br>'+
                            this.cadenaPads + '<br>'+
                            '<b>RESULTADO DEL ESTUDIO</b><br><br>'+
                            '<b>INCIDENTES O ACCIDENTES QUE SE PRESENTARON</b><br><br>'+
                            '<b>PERSONAL QUE INTERVINO EN EL ESTUDIO</b><br><br>'
                        break
                    // Trabajo Social
                    case 15:
                        nota = ''
                        break
                    // Egreso Quirófano
                    case 16:
                        nota = '<b>PROBLEMAS</b><br><br>'+
                            this.cadenaPads+'<br><br/>'+
                            '<b>SUBJETIVO</b><br><br>'+
                            '<b>OBJETIVO</b><br><br>'+
                            '<b>PLAN DE MANEJO</b><br><br>'+
                            '<b>ESCALA ALDERETE</b><br><br>'
                        break
                    // Nota de defunción
                    case 17:
                        nota ='<b>FECHA DE INGRESO:</b>'+ this.datosInternamiento.dateCreated+'<br/><br>'+
                            '<b>FECHA DE DEFUNCIÓN:</b> ----------INGRESAR FECHA DE DEFUNCIÓN------------<br/><br>'+
                            '<b>DIAGNÓSTICO DE INGRESO</b><br><br>'+
                            '<b>DIAGNÓSTICOS FINALES</b><br>'+this.cadenaPads+'<br><br>'+
                            '<b>DIAGNÓSTICOS DE DEFUNCIÓN</b><br><br>'+
                            '<br><b>RESUMEN</b><br><br>'
                        break
                    // Resumen clínico
                    case 18:
                        nota ='<b>ENVIADO A:</b><br/><br>'+
                            '<b>PADECIMIENTOS ACTUALES</b><br>'+this.cadenaPads+'<br>'+
                            '<b>EVOLUCIÓN</b><br><br>'+
                            '<b>SOLICITUDES</b><br><br>'+
                            '<b>PRONÓSTICO</b><br><br>'
                        break

                    // Nota de quimioterapia
                    case 19:
                        nota ='<b>1. Dieta</b><br><br><br>'+
                            '<b>2. Soluciones</b><br><br><br>'+
                            '<b>3. Quimioterapia</b><br><br><br>'+
                            '<b>4. Medicamentos</b><br><br><br>'+
                            '<b>5. Anotaciones adicionales</b><br><br><br>'
                        break
            }
            this.nota = nota
        },
    },
}
</script>

<style>

</style>