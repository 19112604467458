<template>
    <v-sheet elevation="4" class="ma-3">
        
        <v-card>
            <v-toolbar  dark color="primary">
                <v-toolbar-title>
                    

                ({{dp.cip}}) {{dp.nombres}}

                </v-toolbar-title>
                
                
                <v-spacer></v-spacer>

                <v-tooltip bottom color="warning">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            @click="dialogOpenEvento=true"
                        >
                            mdi-file-alert
                        </v-icon>
                    </template>
                <span>Reportar evento adverso</span>
                </v-tooltip>

                <v-btn icon @click="cargaOcupacion">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-sheet class="pa-1 title" elevation="1">
                    <div class="headline">
                        <v-btn small text class="my-3" @click="abrefichaId=true">
                            <v-icon>mdi-card-account-details</v-icon>
                        </v-btn>
                        Ficha de identificación
                    </div>
                    <v-divider/>
                    <v-row no-gutters>
                        <v-col class="px-2 " xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Ingreso: <span class="body-2">{{dp.dateCreated}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Edad: <span class="body-2">{{dp.edad}} ({{dp.fechaNacimiento}})</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Sexo: <span class="body-2">{{dp.sexo}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            C.U.R.P.: <span class="body-2">{{dp.curp}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Area: <span class="body-2">{{dp.areaNombre}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Servicio: <span class="body-2">{{dp.servicioNombre}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">
                            Cama: <span class="body-2">{{dp.cama}}</span>
                        </v-col>
                        <v-col class="px-2" xs="12" sm="6" md="4" lg="3" xl="3" cols="12">

                                Grupo sanguíneo: <span class="body-2">{{dp.sangre}}</span>

                                <v-menu
                                offset-y
                                v-model="abreCambiaSangre"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet width="250px" class="pa-5">
                                    Seleccione grupo sanguineo
                                    <v-select
                                        rounded
                                        filled
                                        dense
                                        v-model="sangre"
                                        :items='["O+", "A+", "B+", "O-", "A-", "AB+", "B-", "AB-"]'
                                        @input="guardaCambios('sangre')"
                                    />
                                </v-sheet>
                            </v-menu>
                        </v-col>
                        <v-col class="px-2" cols="12">
                            Alergias:<br/> <span class="body-2">{{dp.alergias}}</span>

                            <v-menu
                                offset-y
                                v-model="abreCambiaAlergias"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet width="350px" class="pa-5">
                                    <v-text-field
                                        rounded
                                        filled
                                        dense
                                        label="Alergias de este paciente"
                                        v-model="alergias"
                                    />
                                    <v-btn small dark class="primary" @click="guardaCambios('alergias')">Actualizar</v-btn>
                                </v-sheet>
                            </v-menu>

                        </v-col>
                        <v-col class="px-2" cols="12">
                            Motivo del ingreso:<br/> <span class="body-2">{{dp.motivo}}</span>
                            <v-menu
                                offset-y
                                v-model="abreCambiaMotivo"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet width="350px" class="pa-5">
                                    <v-text-field
                                        rounded
                                        filled
                                        dense
                                        label="Motivo de ingreso"
                                        v-model="motivo"
                                    />
                                    <v-btn small dark class="primary" @click="guardaCambios('motivo')">Actualizar</v-btn>
                                </v-sheet>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-tabs
                    v-model="tab"
                    show-arrows
                    slider-size=10
                    slider-color="primary lighten-3"
                    class="pt-2"
                    dark
                    background-color="primary"
                    center-active
                    height="50"
                >
                    <v-tab class="text-body-2">Indicaciones</v-tab> <!-- 0 -->
                    <v-tab class="text-body-2">Notas</v-tab> <!-- 1 -->
                    <v-tab class="text-body-2">Padecimiento<br/>Procedimientos</v-tab> <!-- 2 -->
                    <v-tab class="text-body-2">Enfermería</v-tab> <!-- 3 -->
                    <v-tab class="text-body-2">Cirugias</v-tab> <!-- 4 -->
                    <!--
                    <v-tab class="text-body-2">Eventos<br/>adversos</v-tab>
                    -->
                </v-tabs>
                <v-tabs-items v-model="tab" touchless>
                    <!-- Indicaciones 0 -->
                    <v-tab-item >
                        <list-indicas
                            :internamiento="dp.id"
                        />
                    </v-tab-item>

                    <!-- Notas 1 -->
                    <v-tab-item >
                        <list-notas
                            :internamiento="dp.id"
                            :datosInternamiento="dp"
                        />
                    </v-tab-item>

                    <!-- Padecimientos y procedimientos 2 -->
                    <v-tab-item >
                        <pade-proc
                            :internamiento="dp.id"
                            :datosInternamiento="dp"
                        />
                    </v-tab-item>

                    <!-- Hoja de enfermería 3 -->
                    <v-tab-item >
                        <hoja-enfermeria/>
                    </v-tab-item>

                    <!-- Cirugias 4 -->
                    <v-tab-item >
                        <paciente-cirugias
                            :dp="{pacienteId:dp.cip}"
                            :tab= "4"
                            :esteTab="tab"
                            :permiteNuevo="true"
                            :internamiento="dp.internamiento"
                        />
                    </v-tab-item>

                    <!-- Eventos adversos -->
                    <!--
                    <v-tab-item>
                        <eventos-adversos
                            :paciente="dp.cip"
                        />
                    </v-tab-item>
                    -->
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <nuevo-evento-adverso
            :dialogOpen.sync="dialogOpenEvento"
            :datos="esteDatoEA"
            :dp="dp"
        />
        <ficha-identificacion
            :abrefichaId.sync="abrefichaId"
            :dp="dp"
        />
    </v-sheet>
</template>

<script>
import Indicas from '@/components/hospital/Indicas'
import Notas from '@/components/hospital/Notas'
import PadeProc from '@/views/hospital/padeProc'

import HojaEnfermeria from '@/components/hospital/Enfermeria'
import PacienteCirugia from '@/components/pacientes/PacienteCirugias'
//import EventosAdversos from '@/components/calidad/ListEventosAdversos'

import NuevoEventoAdverso from '@/components/calidad/NuevoEventoAdverso'
import FichaIdentificacion from '@/components/hospital/enfe/print/FichaIdentificacion'
export default {
    created(){
        this.cargaDatos()
    },
    components:{
        'list-indicas':Indicas,
        'list-notas':Notas,
        'pade-proc':PadeProc,
        'hoja-enfermeria': HojaEnfermeria,
  //      'eventos-adversos': EventosAdversos,
        'nuevo-evento-adverso':NuevoEventoAdverso,
        'ficha-identificacion':FichaIdentificacion,
        'paciente-cirugias': PacienteCirugia,
    },
    data: ()=>({
 
        tab: 0,
        dp:{},
        dialogOpenEvento:false,
        esteDatoEA:{},

        abreCambiaSangre:false,
        abreCambiaAlergias:false,
        abreCambiaMotivo:false,

        abrefichaId:false,

        sangre:'',
        alergias:'',
        motivo:'',
        esteTab:0,
    }),
    methods:{
        
        cargaOcupacion(){
            //console.log('Carga el internamiento '+this.dp.area)
            this.$router.push({path:`/hospital/ocupacion/`+this.dp.area})
        },

        async actualizaDatosPac(tipo){
            
            let params = {}
            let url

            if (tipo == 'motivo'){
                url = '/internamientos/actualizaMotivo'
                params.id = this.dp.id
                params.motivo = this.motivo
            }

            if (tipo == 'sangre' ||  tipo=='alergias'){
                url = '/pacientes/actualizaMed'
                params.id = this.dp.cip
                if (this.sangre){
                    params.sangre = this.sangre
                }
                if (this.alergias){
                    params.alergias = this.alergias
                }
            }
            
            
            try{
                let req = await this.$http({
                    url: url,
                    method:'POST',
                    params:params,
                })
                if (req.data.estado){
                    this.cargaDatos()
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Actualizado correctamente',
                    })
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaDatos(){
            try{
                let req = await this.$http({
                    url:'movimientos/datosencuentro',
                    method:'GET',
                    params:{
                        id: this.$route.params.id,
                    }
                })
                this.dp = req.data.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        guardaCambios(tipo){
            
            this.$swal.fire({
                title: 'Actualizar información',
                text: "Confirme actualización de datos de este paciente",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4CAF50',
                cancelButtonColor: '#F44336',
                confirmButtonText: 'SI Actualizar',
                cancelButtonText: 'NO Actualizar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.actualizaDatosPac(tipo)
                }
            })
        },
    },

    watch:{
        abreCambiaSangre(v){
            if (v){
                this.sangre = this.dp.sangre
            }
            if (!v){
                this.sangre = ''
            }
        },
        abreCambiaAlergias(v){
            if (v){
                this.alergias = this.dp.alergias
            }
            if (!v){
                this.alergias = ''
            }
        },

        abreCambiaMotivo(v){
            if (v){
                this.motivo = this.dp.motivo
            }
            if (!v){
                this.motivo = ''
            }
        },
    },
    

}
</script>

<style>

</style>