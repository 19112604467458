<template>
    <v-dialog
        v-model="dialog"
        max-width="900"
    >

        <v-card>
            <v-card-title class="primary">
                <span class="white--text">Ficha de identificación</span>
                <v-spacer/>
                <v-icon color="white" @click="dialog=false">mdi-close-box</v-icon>
            </v-card-title>

            <v-card-actions>

                <v-spacer/>
                    <v-btn color="primary" @click="print">Imprimir</v-btn>
                    <v-btn color="primary" @click="dialog=false">Cerrar</v-btn>
            </v-card-actions>

            <v-card-text>
                <v-sheet>
                    <div id="printFichaId" :style='{"width":"28","height":"16cm", "border": "1px dotted gray"}'>
                        <!-- Encabezado -->
                        <div
                            :style='{
                                "font-size":"14pt",
                                "line-height": "1",
                            }'
                        >
                            <div :style='{
                                "display": "grid",
                                "grid-template-columns": "20% 50% 30%",
                                "padding":"10px 0px 0px 0px"
                            }'>
                                <div>
                                    <img
                                        src="/images/salud.png"
                                        height="50px"
                                    />
                                </div>
                                <div :style='{"text-align":"center"}'>
                                    <b>Ficha de identificación del paciente</b><br/>
                                    Jefatura de Enfermería
                                </div>
                                <div :style='{"text-align":"right"}'>
                                    <img
                                        src="/images/logo-hno-2020.jpg"
                                        height="38px"
                                    />
                                </div>
                            </div>



                            <!-- Datos del paciente -->
                            <div>
                                <div :style='{
                                    "display": "flex",
                                    "padding":"0px 0px 10px 0px",
                                }'>
                                    <div></div>
                                    <div :style='estilos.campo'>
                                        <span :style='{"font-size":"30pt"}'><b> {{dp.nombres}}</b></span>
                                    </div>
                                </div>
                                <div :style='{"font-size":"12pt", "padding":"0px 0px 10px 0px","text-align":"center"}'>Nombre</div>

                            </div>

                            <div :style='{
                                "display": "grid",
                                "grid-template-columns": "48% 27% 25%",
                            }'>
                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'>Fecha de nacimiento:</div>
                                        <span :style='estilos.campo'>
                                            <span :style='{"font-size":"24pt"}'><b>{{dp.fechaNacimiento}}</b></span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'><br/>Edad:</div>
                                        <span :style='estilos.campo'>
                                            {{dp.edad}}
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'><br/>Sexo:</div>
                                        <span :style='estilos.campo'>
                                            {{(dp.sexo=='m')?'HOMBRE':'MUJER'}}
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div :style='{
                                "display": "grid",
                                "grid-template-columns": "20% 80%",
                                "padding":"10px 0px 10px 0px",
                            }'>


                                <!--
                                    <div :style='{"font-size":"12pt", "padding":"0px 0px 10px 0px","text-align":"center"}'>
                                -->
                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt", }'>Grupo Sanguineo:</div>
                                        <span :style='{...estilos.campo, ...{"font-size":"20pt",}}'>
                                            <b>
                                                {{dp.sangre?dp.sangre:'?'}}
                                            </b>
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'><br/>Alergias:</div>
                                        <span :style='{...estilos.campo, ...{"font-size":"18pt",}}'>
                                            <b>
                                                {{dp.alergias?dp.alergias:'NO REGISTRADAS'}}
                                            </b>
                                        </span>
                                    </div>
                                </div>


                            </div>

                            <div :style='{
                                "display": "grid",
                                "grid-template-columns": "50% 50%",
                                "padding":"10px 0px 10px 0px",
                            }'>
                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'>Ingreso:</div>
                                        <span :style='estilos.campo'>
                                            {{dp.dateCreated}}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div :style='{"display": "flex"}'>
                                        <div :style='{"font-size":"12pt"}'>Expediente:</div>
                                        <span :style='estilos.campo'>
                                            {{dp.cip}}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Los riesgos -->
                            <div :style='{
                                "display": "grid",
                                "grid-template-columns": "40% 20% 40%",
                                "justify-items": "center",
                                "font-size":"10pt",
                                "text-align":"center"
                            }'>
                                <div>
                                    <div :style='{"font-size":"12pt"}'><u>Riesgo de caidas</u></div>
                                    <div :style='{
                                        "text-align":"center",
                                        "font-size":"10pt",
                                        "display": "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                    }'>
                                        <div :style='{"margin":"10px"}'>
                                            <img
                                                src="/images/alto.png"
                                                height="38px"
                                            />
                                            <div>A</div>
                                        </div>
                                        <div :style='{"margin":"10px"}'>

                                            <img
                                                src="/images/medio.png"
                                                height="38px"
                                            />
                                            <div>M</div>
                                        </div>
                                        <div :style='{"margin":"10px"}'>
                                            <img
                                                src="/images/bajo.png"
                                                height="38px"
                                            />
                                            <div>B</div>
                                        </div>

                                    </div>
                                    <div
                                        :style='{
                                            "margin":"0px 0px 0px 50px",
                                            "text-align":"LEFT",
                                            "font-size":"7pt",
                                        }'
                                    >
                                        A: ALTO. Rojo,<br/>
                                        M: MEDIO. Amarillo,<br/>
                                        B: Bajo. Verde,<br/>
                                    </div>

                                </div>

                                <div></div>
                                <div>


                                    <div :style='{
                                        "font-size":"10pt",
                                        "text-align":"center"
                                    }'>
                                        <div>
                                            <div :style='{"font-size":"12pt"}'><u>Riesgo de lesiones por presión</u></div>
                                            <div :style='{
                                                "text-align":"center",
                                                "font-size":"10pt",
                                                "display": "flex",
                                                "align-items": "center",
                                                "justify-content": "center",
                                            }'>
                                                <div :style='{"margin":"10px"}'>
                                                    <img
                                                        src="/images/alto.png"
                                                        height="38px"
                                                    />
                                                    <div>A</div>
                                                </div>
                                                <div :style='{"margin":"10px"}'>
                                                    <img
                                                        src="/images/medio.png"
                                                        height="38px"
                                                    />
                                                    <div>M</div>
                                                </div>
                                                <div :style='{"margin":"10px"}'>
                                                    <img
                                                        src="/images/bajo.png"
                                                        height="38px"
                                                    />
                                                    <div>B</div>
                                                </div>
                                            </div>

                                            <div
                                                :style='{
                                                    "margin":"0px 0px 0px 50px",
                                                    "text-align":"LEFT",
                                                    "font-size":"7pt",
                                                }'
                                            >
                                                A: ALTO. Rojo,<br/>
                                                M: MEDIO. Amarillo,<br/>
                                                B: Bajo. Verde,<br/>
                                            </div>

                                        </div>
                                    </div>




                                </div>
                            </div>



                            <div :style='{
                                "font-size":"10pt",
                                "text-align":"center"
                            }'>
                                <div :style='{"font-size":"12pt"}'><u>Riesgo de transmisión</u></div>
                                <div :style='{
                                    "text-align":"center",
                                    "font-size":"10pt",
                                    "display": "flex",
                                    "align-items": "center",
                                    "justify-content": "center",

                                }'>
                                    <div :style='{"margin":"5px"}'>
                                        <img
                                            src="/images/estandar.png"
                                            height="38px"
                                        />
                                        <div>E</div>
                                    </div>
                                    <div :style='{"margin":"10px"}'>
                                        <img
                                            src="/images/contacto.png"
                                            height="38px"
                                        />
                                        <div>C</div>
                                    </div>
                                    <div :style='{"margin":"10px"}'>
                                        <img
                                            src="/images/gotas.png"
                                            height="38px"
                                        />
                                        <div>G</div>
                                    </div>
                                    <div :style='{"margin":"10px"}'>
                                        <img
                                            src="/images/aire.png"
                                            height="38px"
                                        />
                                        <div>A</div>
                                    </div>
                                    <div :style='{"margin":"10px"}'>
                                        <img
                                            src="/images/protector.png"
                                            height="38px"
                                        />
                                        <div>P</div>
                                    </div>
                                </div>

                                <div
                                    :style='{
                                        "margin":"0px 0px 0px 300px",
                                        "text-align":"LEFT",
                                        "font-size":"7pt",
                                    }'
                                >
                                    E: ESTANDAR. Rojo,<br/>
                                    C: CONTACTO. Amarillo,<br/>
                                    G: GOTAS. Verde,<br/>
                                    A: AEREA. Azul,<br/>
                                    P: PROTECTOR. Gris,<br/>
                                </div>

                            </div>

                            <div :style='{
                                "margin":"0px 10px 10px 10px",
                                "font-size":"8.5pt",
                                "text-align":"right"
                            }'>
                                <b>Impreso</b> {{moment(new Date()).format('dddd MMMM D, YYYY')}} <b>por </b>{{$store.getters['security/username']}}
                            </div>
                            <hr/>


                            <div :style='{
                                "font-size":"7pt",
                                "width":"6.5cm",
                                "height":"1.5cm",
                                "border": "1px dotted gray",
                                "padding":"3px"
                            }'>
                                <div >
                                    <div :style='{"float": "left", "width":"5.1cm",}'>
                                        <B>NOMBRE: </B>{{dp.nombres}}<br/>
                                        <b>F. DE NAC.: </b>{{dp.fechaNacimiento}}<br/>
                                    </div>
                                    <div :style='{"float": "left", "margin": "0 auto"}'>
                                        <img
                                            src="/images/logo-hno-peque.jpg"
                                            width ="33px"
                                        />
                                    </div>
                                </div>
                                <div :style='{"float": "left"}'>
                                    <B>SEXO: </B>{{(dp.sexo=='m')?'HOMBRE':'MUJER'}}<br/>
                                    <B>EXPEDIENTE: </B>{{dp.cip}}<br/>
                                </div>
                            </div>

                        </div>
                    </div>
                </v-sheet>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        abrefichaId:Boolean,
        dp:Object,
    },
    data:()=>({
        estilos:{
            campo:{
                "border-bottom": "5px solid black",
                "border-bottom-width": "thin",
                "margin":"0px 5px 0px 20px",
                "width":"100%",
                "text-align":"center",
                "align-self": "flex-end",
            },
            campoNombre:{
                "border-bottom": "5px solid black",
                "border-bottom-width": "thin",
                "margin":"0px 5px 0px 20px",
                "width":"100%",
                "text-align":"center",
                "font-size":"20pt"
            },
            circulo:{
                "border": "1px solid gray",
                "height": "30px",
                "width": "30px",
                "padding":"5px",
                "margin":"10px",
                "border-radius": "50%",
            },
        },
    }),

    methods:{
        async print(){
            await this.$htmlToPaper('printFichaId',{
                //styles:[
                //    '/css/hojaenf.css'
                //],
            })
        },

    },
    computed:{
        dialog:{
            get(){
                return this.abrefichaId
            },
            set(val){
                this.$emit('update:abrefichaId',val)
            },
        },

    },
    
}
</script>

<style>
@media screen {
    @page {
        size: letter portrait;
    }
    .onlyPrint {
        display: none;
    }
}
</style>


